import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var QueryBuilderIcon = (props)=>{
    return(
        <SvgIcon {...props}>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="-2px" y="-0px"
	 width="44px" height="44px" viewBox="0 0 612 792" enable-background="new 0 0 612 792" >
<g>
	<path d="M-427.53,273.75c1.05,0.65,2.21,0.97,3.37,0.97c2.14,0,4.23-1.08,5.44-3.02c1.86-3,0.94-6.95-2.06-8.81
		c-36.29-22.54-57.96-61.47-57.96-104.14c0-67.54,54.95-122.49,122.49-122.49s122.49,54.95,122.49,122.49
		c0,42.66-21.66,81.59-57.96,104.14c-3,1.86-3.93,5.81-2.06,8.81c1.87,3,5.81,3.92,8.82,2.06c19.19-11.92,35.24-28.6,46.41-48.24
		c11.51-20.25,17.6-43.34,17.6-66.77c0-74.6-60.69-135.29-135.29-135.29s-135.29,60.69-135.29,135.29c0,23.43,6.09,46.52,17.6,66.77
		C-462.78,245.15-446.73,261.83-427.53,273.75L-427.53,273.75z M-427.53,273.75"/>
	<path d="M-356.25,117.72c-36.19,0-65.63,29.44-65.63,65.63c0,36.19,29.44,65.63,65.63,65.63c36.19,0,65.63-29.44,65.63-65.63
		C-290.62,147.17-320.06,117.72-356.25,117.72L-356.25,117.72z M-356.25,236.19c-29.13,0-52.83-23.7-52.83-52.83
		c0-29.13,23.7-52.83,52.83-52.83s52.83,23.7,52.83,52.83S-327.12,236.19-356.25,236.19L-356.25,236.19z M-356.25,236.19"/>
	<path d="M-282.85,288.91c-0.16-0.14-0.33-0.27-0.5-0.4c-0.13-0.09-0.27-0.18-0.41-0.26c-20.45-14.63-45.48-23.25-72.48-23.25
		s-52.04,8.62-72.49,23.25c-0.14,0.08-0.27,0.17-0.41,0.26c-0.17,0.13-0.34,0.26-0.5,0.4c-31.17,22.72-51.46,59.5-51.46,100.94
		c0,3.54,2.87,6.4,6.4,6.4c3.53,0,6.4-2.87,6.4-6.4c0-35.1,16.23-66.48,41.57-87.04l65.3,90.78c1.2,1.67,3.14,2.66,5.2,2.66
		c2.06,0,3.99-0.99,5.2-2.66l65.3-90.78c25.34,20.56,41.57,51.94,41.57,87.04c0,3.54,2.87,6.4,6.4,6.4c3.53,0,6.4-2.87,6.4-6.4
		C-231.39,348.42-251.68,311.64-282.85,288.91L-282.85,288.91z M-356.25,378.89l-60.11-83.57c17.38-11.09,38.01-17.53,60.11-17.53
		s42.73,6.43,60.11,17.53L-356.25,378.89z M-356.25,378.89"/>
</g>
<g>
	<path style={{stroke:"white",strokeWidth:"18px",strokeMiterlimit:"10"}} d="M106.57,275.26c0.99,0.61,2.08,0.91,3.16,0.91c2.01,0,3.98-1.01,5.11-2.84c1.75-2.82,0.88-6.52-1.93-8.27
		c-34.08-21.17-54.42-57.72-54.42-97.78c0-63.42,51.59-115.02,115.02-115.02s115.02,51.59,115.02,115.02
		c0,40.06-20.34,76.61-54.42,97.78c-2.82,1.75-3.69,5.45-1.94,8.27c1.75,2.82,5.46,3.68,8.28,1.93
		c18.02-11.19,33.09-26.86,43.57-45.29c10.81-19.02,16.52-40.7,16.52-62.7c0-70.05-56.99-127.03-127.03-127.03
		S46.47,97.22,46.47,167.27c0,22,5.71,43.68,16.53,62.7C73.47,248.4,88.54,264.06,106.57,275.26L106.57,275.26z M106.57,275.26"/>
	<path  style = {{stroke:"white",strokeWidth:"18px",strokeMiterlimit:"10"}} d="M171.31,129.51c-36.19,0-65.63,29.44-65.63,65.63c0,36.19,29.44,65.63,65.63,65.63
		c36.19,0,65.63-29.44,65.63-65.63C236.94,158.95,207.5,129.51,171.31,129.51L171.31,129.51z M171.31,247.97
		c-29.13,0-52.83-23.7-52.83-52.83c0-29.13,23.7-52.83,52.83-52.83s52.83,23.7,52.83,52.83S200.44,247.97,171.31,247.97
		L171.31,247.97z M171.31,247.97"/>
	<path  style = {{stroke:"white",strokeWidth:"18px",strokeMiterlimit:"10"}} d="M244.71,308.7c-0.16-0.14-0.33-0.27-0.5-0.4c-0.13-0.09-0.27-0.18-0.41-0.26
		c-20.45-14.63-45.48-23.25-72.48-23.25s-52.03,8.62-72.49,23.25c-0.14,0.08-0.27,0.17-0.41,0.26c-0.18,0.13-0.34,0.26-0.5,0.4
		c-31.17,22.72-51.46,59.5-51.46,100.94c0,3.54,2.87,6.4,6.4,6.4c3.53,0,6.4-2.87,6.4-6.4c0-35.1,16.23-66.48,41.57-87.04
		l65.3,90.78c1.2,1.67,3.14,2.66,5.19,2.66s3.99-0.99,5.2-2.66l65.3-90.78c25.34,20.56,41.57,51.94,41.57,87.04
		c0,3.54,2.87,6.4,6.4,6.4c3.54,0,6.4-2.87,6.4-6.4C296.17,368.2,275.87,331.42,244.71,308.7L244.71,308.7z M171.31,398.68
		l-60.11-83.57c17.38-11.09,38.01-17.53,60.11-17.53s42.73,6.43,60.11,17.53L171.31,398.68z M171.31,398.68"/>
</g>
</svg>

        </SvgIcon>
    )
}
export default QueryBuilderIcon;

