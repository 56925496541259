import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import isLoggedIn from "services/UserService.js";
import routes, {authenticatedRoutes}  from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import logo2 from "assets/img/lasalleMini.svg";
import logo4 from "assets/img/lasalleLogo.svg";
let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          //component={prop.component}
          component={prop.externalLink !== undefined ? ( ()=>{
            sessionStorage.removeItem('usr');
            window.location.href=prop.externalLink
          } ) : prop.component}
          key={key}
        />
      );
    })}
    {authenticatedRoutes.map((prop, key) => {
      if(prop.requiresRoute === false)
        return null;
      return (
        <Route
          path={prop.path}
          //component={prop.component}
          component={prop.externalLink !== undefined ? ( ()=>{
            sessionStorage.removeItem('usr');
            window.location.href=prop.externalLink
          } ) : prop.component}
          key={key}
        />
      );
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [loggedIn, setLoggedIn] = React.useState(isLoggedIn());
  const [image, setImage] = React.useState(undefined);//we wont be using any images as background, we will go with an specific color
  const [color, setColor] = React.useState("yellow");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (

    <div className={classes.wrapper} style={{background:"white !important"}}>
      <Sidebar
        routes={routes}
        authenticatedRoutes={authenticatedRoutes}
        logoText={"La Salle"}
        logo={logo2}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          logo={logo2}
          logoMobile={logo4}
          handleDrawerToggle={handleDrawerToggle}
          
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>   
        }
        <Footer/>  
        {
          /*
        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        />*/
        }
        
      </div>
    </div>
  );
}
