import React from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";


const styles = {
  grid: {
    padding: "10px 15px !important"
  },
  gridSmallDevices:{
    padding: "10px 2px !important"
  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid item {...rest} className={isMobileDevice ? classes.gridSmallDevices : classes.grid}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node
};
