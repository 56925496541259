import React from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from 'clsx';


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
// core components
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import GridContainer from "components/Grid/GridContainer";
import dummyAvatar from "assets/img/faces/dummy.png";
import { Typography } from "@material-ui/core";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import {drupalDateFormatter} from "components/Calendar/MiscFunctions";
import customIcons from "views/svgIcons.js"
import PageNavigator from "components/PageNavigator/PageNavigator";
import axios from 'axios';
import isLoggedIn from "services/UserService";
import useDebounce from 'components/valueDebouncer/debouncer.js';
import {getFormattedDate} from "components/Calendar/MiscFunctions";
import TextField from '@material-ui/core/TextField';

import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import InputBase from '@material-ui/core/InputBase';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';

import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';

import SvgIcon from '@material-ui/core/SvgIcon';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import {apiURL3} from "variables/restApi";


const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    color:"black",
    fontSize:"14px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardFooterStats: {
    borderTop: "1px solid " + grayColor[10],
    marginTop:"10px",
    marginLeft:"10px",
    marginRight:"15px",
    paddingBottom:"10px",
    "& svg": {
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "16px",
      height: "16px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "16px",
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  emphasizedText:{
    fontSize:"16px",
    "& et":{
      fontFamily:"IndivisaTextSansBold",
      color:"black"
    }
  },
  selectItem:{
    textAlign:"center", 
    fontFamily:"inherit", 
    fontSize:"18px"
  },
  dropDown:{
    color:"black",
    fontSize:"18px", 
    display:"flex", 
    alignItems:"center", 
    textAlign:"center", 
    justifyContent:"center", 
    background:"white", 
    paddingTop:"10px", 
    paddingBottom:"15px", 
    borderRadius:"6px"
  }
  
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const paises = [
  {display: 'Guatemala', Acronym: 'gt'},
  {display: 'Costa Rica', Acronym: 'cr'},
  {display: 'Honduras', Acronym: 'hn'},
  {display: 'Nicaragua', Acronym: 'ni'},
  {display: 'El Salvador', Acronym: 'sv'},
  {display: 'Belice', Acronym:'bz'},
  {display: 'Panamá', Acronym:'pa'},
  {display: 'España', Acronym:'es'},
  {display: 'Colombia', Acronym:'col'}
];

const obras = [
    'La Antigua Guatemala, Colegio',
    'Huehuetenango, Colegio'
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStylesExpansionPanel = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const useStylesPanel = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const useStyles = makeStyles(styles);

function getPagginatorIndex()
{
    var previousState = sessionStorage.getItem('str');
    if(previousState === null)
    {
        
        //sessionStorage.setItem('str',previousDate);
        sessionStorage.setItem('str',JSON.stringify({date:new Date(), med: 1, her: 1}));
        return 1;
    }
    else
    {
        previousState = JSON.parse(sessionStorage.getItem('str'));
        return previousState.her;
    }
}

export default function DirectorioHermanos(props) {
  
  var viewType = undefined;
  if(props.location.state !== null && props.location.state !== undefined)
  {
    viewType = props.location.state.viewType;
  }
  const classes = useStyles();
  const classesExpasionPanel = useStylesExpansionPanel();
  const classesPanel = useStylesPanel();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [pais, setPais] = React.useState("all");
  const [comunidad, setComunidad] = React.useState("all");
  const [comunidadesInfo, setComunidadesInfo] = React.useState({isLoading:true, data:[], ask: true});
  const [currentIndex, setCurrentIndex] = React.useState(getPagginatorIndex());
  const [expanded, setExpanded] = React.useState(false);
  const [currentName, setCurrentName] = React.useState("all");
  var   debouncedName  = useDebounce(currentName, 500);

  var debouncedValue = useDebounce(currentIndex, 500);
  const [obra, setObra] = React.useState([]);
  const [data =[], setData] = React.useState();
  var loggedState = isLoggedIn();

  React.useEffect(()=>{
    console.log("use effect from index")    ;
    if(loggedState !== false)
    {
      if(viewType !== undefined)
      {
        var todaysDate = new Date();
        var desiredField = (viewType === 1 ? ("field_votos_temporales") : (viewType === 2 ? "field_votos_perpetuos" : "field_toma_de_habito"))
        
        axios.get(apiURL3+"aniversarios/"+getFormattedDate(todaysDate)+"/"+desiredField, {withCredentials:true}).then((res)=>{
            setData(res.data);
          }).catch((error)=>{
            console.log(error);
        })
      }
      else
      {
        if(debouncedValue === 0)
          debouncedValue = 1;        
        axios.get(apiURL3+"hermanosfiltros/"+debouncedValue + "?c="+pais + "&cid="+comunidad + "&nombre=" + debouncedName, {withCredentials:true}).then((res)=>{
          var currentState = JSON.parse(sessionStorage.getItem('str'))
          currentState.her = +debouncedValue;
          sessionStorage.setItem('str',JSON.stringify(currentState));
          var difuntos = res.data.filter((item) => {
            return item.field_fecha_de_defuncion.length >= 1;
          });
          var vivos   = res.data.filter((item) => {
            return item.field_fecha_de_defuncion.length == 0;
          });
          var infoToShow = vivos.concat(difuntos);
          setData(infoToShow);
        }).catch((error)=>{
          console.log(error);
        });
      }

      if(comunidadesInfo.ask == true ){
        axios.get(apiURL3+"comunidades")
        .then((res) => {
           setComunidadesInfo({isLoading:false,data:res.data, ask: false}); 
        });
      }
    }
  }, [debouncedValue, pais, comunidad, debouncedName]);
  

  if(isLoggedIn() === false)
  return null;  

  const handleChange2 = (event) =>{
      setObra(event.target.value);
  }
  function renderHermanos()
  {
    
    var imageToShow = (element)=>{
      if(element.field_fotografia.length === 0)
        return null;
      else
        return (
          <div style={{ paddingRight:"10px", display: "flex", alignItems:"center", height:"inherit", paddingBottom:"20px"}}>
                      
            <img src={element.field_fotografia_url} style={{width:"155px",height:"157px", borderTopLeftRadius:"3px"}} />
          </div>
        );
        
    }
    var renderCardSubtitle = (subtitle, element, element2=undefined, isDate=false)=>{
      if(element !== undefined && element !== null)
      {
        element = element.value
        if(element2 !== undefined && element !== null)
        {
          element2 = element2.value;
          if(element2 !== "")
           element = element + (" - ")+element2
        }
        if(element === "")
          return null;
        else
        {
          return (
              <React.Fragment>
              <et is="custom">{subtitle}</et>{isDate !== false ? drupalDateFormatter(element) : element}
              <br/>
              </React.Fragment>
          )
        }
      }
      else
        return null;
    }
    return (
      data.map((element, index)=>{
        var firstName = element.field_nombres.length > 0 ? (element.field_nombres.reduce((acumulator, currentVal)=>({value: acumulator.value+" "+currentVal.value}))): {value:""};
        var lastName = element.field_apellidos.length > 0 ? ( element.field_apellidos.reduce((acumulator, currentVal)=>({value: acumulator.value+" "+currentVal.value}))) : {value:""};
        var realName = (firstName.value+" "+lastName.value)
        return(
              <GridItem key={index} xs={12} sm={12} md={6} style={{padding: "5px !important"}}>
                <div style={{background:"transparent", borderRadius:"6px"}}>
                  <Link to={
                      {       
                        pathname: "/element", 
                        state:{
                          type: 4,
                          title: realName,
                          icon:"face",
                          obj: element,                          
                          iconColor:"info",
                          dynamicFont:true
                        }
                      } 
                    }
                      style={{color:"inherit"}}>
                    <div style={{ display: "flex", alignItems:"center", paddingTop:"10px", height:"100%"}}>
                      
                      <div style={{paddingLeft:"0px", display: "flex", alignItems:"center", width: "100%"}}>
                        <div style={{ width:"100%" }}>
                          <Card>   
                            <CardHeader>
                              <CardIcon color={"primary"}>
                                {
                                  (element.field_fecha_de_defuncion.length >= 1)?
                                    <SvgIcon>
                                    <svg
                                    aria-hidden="true"
                                    data-prefix="fab"
                                    data-icon="github-alt"
                                    fill="white"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    >
                                    <path d="m404.617 120.5h-73.367v-73.367c0-26.304-21.351-47.133-47.133-47.133h-56.233c-26.304 0-47.133 21.351-47.133 47.133v73.367h-73.367c-26.304 0-47.133 21.351-47.133 47.133v56.233c0 26.304 21.351 47.133 47.133 47.133h73.367v193.867c0 26.304 21.351 47.133 47.133 47.133h56.233c26.304 0 47.133-21.351 47.133-47.133v-193.866h73.367c26.304 0 47.133-21.351 47.133-47.133v-56.233c0-26.305-21.351-47.134-47.133-47.134zm17.133 103.367c0 9.337-7.548 17.133-17.133 17.133h-88.367c-8.284 0-15 6.716-15 15v208.867c0 9.337-7.548 17.133-17.133 17.133h-56.233c-9.337 0-17.133-7.548-17.133-17.133v-208.867c0-8.284-6.716-15-15-15h-88.367c-9.337 0-17.133-7.548-17.133-17.133v-56.233c0-9.337 7.548-17.133 17.133-17.133h88.367c8.284 0 15-6.716 15-15v-88.368c0-9.337 7.548-17.133 17.133-17.133h56.233c9.337 0 17.133 7.548 17.133 17.133v88.367c0 8.284 6.716 15 15 15h88.367c9.337 0 17.133 7.548 17.133 17.133z"/>
                                    </svg>{" "}
                                  </SvgIcon>
                                  :""
                                }
                                {
                                  (element.field_fecha_de_defuncion.length == 0)?
                                    <SvgIcon>
                                    <svg
                                    aria-hidden="true"
                                    data-prefix="fab"
                                    
                                    fill="white"
                                    
                                    xmlns="http://www.w3.org/2000/svg"
                                    
                                    id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.4 144.3"
                                    >                                    
                                    <polygon class="cls-1" points="118.32 26.75 83.2 42.36 48.08 26.75 20.51 116.49 68.24 127.55 83.2 61.22 98.16 127.55 145.89 116.49 118.32 26.75"/>
                                    </svg>{" "}
                                  </SvgIcon>
                                  :""
                                }
                                </CardIcon>
                                
                            </CardHeader>  
                            <CardBody plain style={{ paddingLeft: "20px", height:"auto", display:"flex", flexDirection:"row", minHeight:"150px"}}>
                              <Hidden smDown>
                                <div style={{display:"flex", flexDirection:"row", alignItems:"flex-start"}}>
                                  {imageToShow(element)}  
                                  <Box fontWeight="fontWeightBold" fontSize="18px" color="black">                                  
                                    <div style={{ display:"flex",flexDirection:"column"}}>
                                      {realName}
                                      <p className={classes.emphasizedText}>
                                        {
                                          renderCardSubtitle("Fecha de nacimiento: ",element.field_nacimiento[0],undefined, true)
                                        }
                                        {
                                          renderCardSubtitle("Fecha de defunción: ",element.field_fecha_de_defuncion[0],undefined, true)
                                        }
                                      </p>
                                    </div>
                                  </Box>
                                </div>
                              </Hidden>
                              <Hidden mdUp>
                              <div >
                                  {imageToShow(element)}  
                                  <Box fontWeight="fontWeightBold" fontSize="18px" color="black">                                  
                                    <div style={{ display:"flex",flexDirection:"column"}}>
                                      {realName}
                                      <p className={classes.emphasizedText}>
                                        {
                                          renderCardSubtitle("Fecha de nacimiento: ",element.field_nacimiento[0],undefined, true)
                                        }
                                        {
                                          renderCardSubtitle("Fecha de defunción: ",element.field_fecha_de_defuncion[0],undefined, true)
                                        }
                                      </p>
                                    </div>
                                  </Box>
                                </div>
                              </Hidden>
                            </CardBody>                     
                            <CardFooter stats>
                              <div className={classes.cardFooterStats}>
                                <div className={classes.stats}>                                    
                                <Button variant="contained" style={{ marginLeft: "-4px", backgroundColor:'#004B92', color:'white' }}>Leer más</Button>
                                </div>
                              </div>
                            </CardFooter>
                          </Card>                          
                        </div>
                        
                      </div>
                    </div>
                  </Link>
                </div>
              </GridItem>
        )
      })

    )
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const mostrarComunidades = () => {
      if(comunidadesInfo.isLoadding == true){
        return (
          <MenuItem  value={"s"} key={"seleccionar"} disabled className={classes.selectItem}>Cargando...</MenuItem>
        );
      }
      return (
        comunidadesInfo.data.map((item) => {
            return(
              <MenuItem  value={ item.nid[0].value } key={item.nid[0].value } className={classes.selectItem}>
                {item.title[0].value}
              </MenuItem>
            );
          }
        )
      );
  };

  return (
    <div>
      <Hidden mdUp>
          <div style={{ marginLeft:"-15px", display: "flex", alignItems:"center",  marginTop:"-30px", marginBottom:"-20px" /*the negative is to reduce gap between navbar and this component. Because of GridItem styles*/}}>
            {customIcons[3].icon}
            <h4 style={{paddingLeft:"5px", fontFamily:"IndivisaTextSansBold", color:"black"}}>Directorio de Hermanos</h4>
          </div>
      </Hidden>
      <GridContainer>
      <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingBottom: isMobileDevice  ? "20px" : "0px"}}>
            <Paper component="div" className={""} elevation={0}>
              
              <div className={classesExpasionPanel.root}>    
                    <Paper elevation={0} 
                      style={{
                        display: "flex",
                        flexDirection: "row"
                      }}
                    >  
                        <IconButton
                          onClick={
                            (event) => {
                              setPais("all");
                              setCurrentIndex(1);
                              setComunidad("all");
                              setCurrentName("all");                              
                            }
                          }
                        >
                          <ClearIcon />
                        </IconButton>  
                        <IconButton className={""} aria-label="search" onClick={
                          (event) => {console.log("efe"); setPais(pais)}
                        }>
                          <SearchIcon />
                        </IconButton>

                        <InputBase
                          className={"search-input"}
                          placeholder="Buscar Hermano"
                          inputProps={{ 'aria-label': 'Buscar Hermano' }}
                          onChange={ event => setCurrentName(event.target.value.toLowerCase()) }
                          
                        />
 

                        <IconButton
                          className={clsx(classesPanel.expand, {
                            [classesPanel.expandOpen]: expanded,
                          })}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                        <ExpandMoreIcon />
                      </IconButton> 
                    </Paper>       
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className={"filters"}>
                      <Typography>
                      <div className={classes.dropDown}> 
                      <FormControl
                          style={{
                            width: "96%"
                          }}
                      >                       
                        <InputLabel id="Comunidad">Comunidad</InputLabel>
                          <Select
                            labelId="Comunidad"
                            value={comunidad}
                            displayEmpty
                            onChange={event => setComunidad(event.target.value)}
                            input={<Input />}
                            MenuProps={MenuProps}
                            style={{width:"98%", fontFamily:"inherit", fontSize:"18px"}}
                            >
                            <MenuItem  value={"s"} key={"seleccionar"} disabled className={classes.selectItem}>Seleccionar</MenuItem>
                            {mostrarComunidades()}
                          </Select>              
                      </FormControl>
                      </div>
                      </Typography>

                      <Typography>
                      <div className={classes.dropDown}>   
                      <FormControl 
                        style={{
                          width: "96%"
                        }}
                      >                              
                          <InputLabel id="País">País</InputLabel>
                          <Select
                            labelId="País"
                            value={pais}
                            displayEmpty
                            onChange={event => setPais(event.target.value)}
                            input={<Input />}
                            MenuProps={MenuProps}
                            style={{width:"98%", fontFamily:"inherit", fontSize:"18px"}}
                            >
                            <MenuItem  value={"s"} key={"seleccionar"} disabled className={classes.selectItem}>Seleccionar</MenuItem>
                            {paises.map((pais) => (
                                <MenuItem key={pais.Acronym} value={pais.Acronym} className={classes.selectItem}>
                                {pais.display}
                                </MenuItem>
                            ))}
                            </Select>              
                          
                      </FormControl>         
                      </div>
                      </Typography>
                    </div> 
                    </Collapse>

              </div>
            </Paper>
          </GridItem> 
      </GridContainer>
      {viewType === undefined ? (
        <PageNavigator
          setPage={setCurrentIndex}
          rootIndex={currentIndex}
          maxValue={15}
        />) : null}
      
      <GridContainer>         
        {renderHermanos()}
      </GridContainer>
    </div>
  );
}
