import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var CruzIcon = (props)=>{
    return(
        <SvgIcon {...props}>
                <svg
                aria-hidden="true"
                data-prefix="fab"
                data-icon="github-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                >
                <path d="m404.617 120.5h-73.367v-73.367c0-26.304-21.351-47.133-47.133-47.133h-56.233c-26.304 0-47.133 21.351-47.133 47.133v73.367h-73.367c-26.304 0-47.133 21.351-47.133 47.133v56.233c0 26.304 21.351 47.133 47.133 47.133h73.367v193.867c0 26.304 21.351 47.133 47.133 47.133h56.233c26.304 0 47.133-21.351 47.133-47.133v-193.866h73.367c26.304 0 47.133-21.351 47.133-47.133v-56.233c0-26.305-21.351-47.134-47.133-47.134zm17.133 103.367c0 9.337-7.548 17.133-17.133 17.133h-88.367c-8.284 0-15 6.716-15 15v208.867c0 9.337-7.548 17.133-17.133 17.133h-56.233c-9.337 0-17.133-7.548-17.133-17.133v-208.867c0-8.284-6.716-15-15-15h-88.367c-9.337 0-17.133-7.548-17.133-17.133v-56.233c0-9.337 7.548-17.133 17.133-17.133h88.367c8.284 0 15-6.716 15-15v-88.368c0-9.337 7.548-17.133 17.133-17.133h56.233c9.337 0 17.133 7.548 17.133 17.133v88.367c0 8.284 6.716 15 15 15h88.367c9.337 0 17.133 7.548 17.133 17.133z"/>
                </svg>{" "}
        </SvgIcon>
    )
}
export default CruzIcon;