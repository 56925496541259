import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
///Calendar modal
import { MuiPickersUtilsProvider ,DatePicker } from "@material-ui/pickers";
import { es } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import Modal from '@material-ui/core/Modal';
///Core components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { yellowColor } from "assets/jss/material-dashboard-react.js";
//Icons
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Button } from '@material-ui/core';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DateModal(props) {
  const {handleChange, setSelected, date, type, customOpen, customOpenCtrl, justIcon} = props;

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const [localDate, setLocalDate] = React.useState(date);
 
  React.useEffect(()=>{
    setLocalDate(date)
  }, [date]);

  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndSave = () =>{
    //setSelected(localDate)
    if(handleChange !== undefined)
      handleChange(localDate);
    else
      console.log("handleChange es undfined zzz")
    if(customOpenCtrl === undefined)
        setOpen(false);
    else
      customOpenCtrl(false);
    
  }

  const body = (
    <div>
      <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <Grid item>
              <DatePicker
                  autoOk
                  variant="static"
                  openTo="date"
                  views={['month', 'year', 'date']}
                  format="dd/MM/yyyy"
                  value={localDate}
                  onChange={setLocalDate}
              />
              <div style={{textAlign:"center"}}>
                <br/>
                <Button variant="contained" style={{color:"white", fontFamily:"IndivisaTextSansBold", fontSize:"18px", borderRadius:"6px", background:yellowColor[0]}} onClick={() => handleCloseAndSave()}>Seleccionar</Button>
              </div>
              
            </Grid>
          </Grid>
          
      </MuiPickersUtilsProvider>
    </div>
  );
  //console.log("Date es ->"+date);
  if(type === 'printModal')
  {
    return (
      <div>
        <Modal
          open={customOpen}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display:'flex', alignItems:'center',justifyContent:'center'}}
        >
          {body}
        </Modal>
      </div>
    )
  }
  else if(justIcon)
  {
    return (
      <div > 
        <Box textAlign="left" onClick={handleOpen} style={{cursor:"pointer", display:"flex", alignItems: "center"}}>
          <CalendarTodayIcon fontSize="large"/>
        </Box>
      
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display:'flex', alignItems:'center',justifyContent:'center'}}
        >
          {body}
        </Modal>
      </div>
    )
  }

  return (
    <React.Fragment>
    <Hidden mdUp>
      <div style={{width:"100%"}}>
        <Box flex="100%" textAlign="center" style={{/*cursor:"pointer" zzz,*/ color:"black", fontFamily:"IndivisaTextSansBold"}} /*onClick={handleOpen} zzz*/>
          {localDate === undefined ? "" : localDate.toLocaleDateString("es-ES",{ year: 'numeric', month: 'long', day: 'numeric' })}
          
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display:'flex', alignItems:'center',justifyContent:'center'}}
        >
          {body}
        </Modal>
      </div>
    </Hidden>
    <Hidden smDown>
      <div > 
        <Box textAlign="left" onClick={handleOpen} style={{cursor:"pointer", display:"flex", alignItems: "center"}}>
          <CalendarTodayIcon fontSize="large"/>
        </Box>
      
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{display:'flex', alignItems:'center',justifyContent:'center'}}
        >
          {body}
        </Modal>
      </div>
    </Hidden>
    </React.Fragment>
    
  );
}
