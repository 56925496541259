import React from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem.js";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import GridContainer from "components/Grid/GridContainer";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import {drupalDateFormatter} from "components/Calendar/MiscFunctions";
import axios from 'axios';
import isLoggedIn from "services/UserService";
import useDebounce from 'components/valueDebouncer/debouncer.js';
import {getFormattedDate} from "components/Calendar/MiscFunctions";
import {apiURL3} from "variables/restApi";
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardFooterStats: {
    borderTop: "1px solid " + grayColor[10],
    "& svg": {
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "16px",
      height: "16px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "16px",
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  emphasizedText:{
    fontSize:"16px",
    "& et":{
      fontFamily:"IndivisaTextSansBold",
      color:"black"
    }
  },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const paises = [
  'Guatemala',
  'Costa Rica',
  'Honduras',
  'Nicaragua',
  'Panamá'
]

const obras = [
    'La Antigua Guatemala, Colegio',
    'Huehuetenango, Colegio'
]

const useStyles = makeStyles(styles);

function getPagginatorIndex()
{
    var previousState = sessionStorage.getItem('str');
    if(previousState === null)
    {
        
        //sessionStorage.setItem('str',previousDate);
        sessionStorage.setItem('str',JSON.stringify({date:new Date(), med: 1, her: 1}));
        return 1;
    }
    else
    {
        previousState = JSON.parse(sessionStorage.getItem('str'));
        return previousState.her;
    }
}

export default function Aniversarios(props) {
  console.log("props.location.state");
  console.log(props);
  var viewType = undefined;
  if(props.location.state !== null && props.location.state !== undefined)
  {
    viewType = props.location.state.viewType;
  }
  const classes = useStyles();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [pais, setPais] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(1);
  var debouncedValue = useDebounce(currentIndex, 500);
  const [obra, setObra] = React.useState([]);
  const [data =[], setData] = React.useState();
  var loggedState = isLoggedIn();

  React.useEffect(()=>{
    if(loggedState !== false)
    {
      if(viewType !== undefined)
      {
        var todaysDate = new Date();
        var desiredField = (viewType === 1 ? ("field_votos_temporales") : (viewType === 2 ? "field_votos_perpetuos" : "field_toma_de_habito"))
        axios.get(apiURL3+"aniversarios/"+getFormattedDate(todaysDate)+"/"+desiredField, {withCredentials:true}).then((res)=>{
            setData(res.data);
          }).catch((error)=>{
            console.log(error);
        })
      }
    }
  }, [debouncedValue]);

  if(isLoggedIn() === false)
  return null;

  const handleChange = (event) => {
    setPais(event.target.value);
  };

  const handleChange2 = (event) =>{
      setObra(event.target.value);
  }
  function renderHermanos()
  {
    
    var imageToShow = (element)=>{
      if(element.field_fotografia.length === 0)
        return null;
      else
        return (
          <div style={{ paddingLeft:"10px", display: "flex", alignItems:"center", height:"inherit"}}>
                      
            <img src={element.field_fotografia_url} style={{width:"130px",height:"150px", borderTopLeftRadius:"3px"}} />
          </div>
        );
        
    }
    var renderCardSubtitle = (subtitle, element, element2=undefined, isDate=false)=>{
      if(element !== undefined && element !== null)
      {
        element = element.value
        if(element2 !== undefined && element !== null)
        {
          element2 = element2.value;
          if(element2 !== "")
           element = element + (" - ")+element2
        }
        if(element === "")
          return null;
        else
        {
          return (
              <React.Fragment>
              <et is="custom">{subtitle}</et>{isDate !== false ? drupalDateFormatter(element) : element}
              <br/>
              </React.Fragment>
          )
        }
      }
      else
        return null;
    }
    return (
      data.map((element, index)=>{
        var firstName = element.field_nombres.length > 0 ? (element.field_nombres.reduce((acumulator, currentVal)=>({value: acumulator.value+" "+currentVal.value}))): {value:""};
        var lastName = element.field_apellidos.length > 0 ? ( element.field_apellidos.reduce((acumulator, currentVal)=>({value: acumulator.value+" "+currentVal.value}))) : {value:""};
        var realName = (firstName.value+" "+lastName.value)
        return(
              <GridItem key={index} xs={12} sm={12} md={6}>
                <div style={{background:"white", borderRadius:"6px"}}>
                  <Link to={{pathname: "/element", state:{
                        type: 4,
                        title: realName,
                        icon:"face",
                        obj: element,
                        iconColor:"info"
                      }}} style={{color:"inherit"}}>
                    <div style={{ display: "flex", alignItems:"center", paddingTop:"10px", height:"100%"}}>
                      {imageToShow(element)}
                      <div style={{paddingLeft:"10px", display: "flex", alignItems:"center"}}>
                        <div>
                          <Box fontWeight="fontWeightBold" fontSize="18px" color="black">
                            {realName}
                            {/*
                            <br/>
                            {cardData(element)}
                            */}
                          </Box>
                          <p className={classes.emphasizedText}>
                          {
                            renderCardSubtitle("Fecha de defunción: ",element.field_fecha_de_defuncion[0],undefined, true)
                          }
                          </p>
                        </div>
                        
                      </div>
                    </div>
                    <div className={classes.cardFooterStats}>
                      <div className={classes.stats}>
                        <Link to={{pathname: "/element", state:{
                          type: 4,
                          title: realName,
                          icon:"face",
                          obj: element,
                          iconColor:"info"
                        }}}>
                            <Icon>arrow_downward</Icon>{/*Warning mini-icon */}
                            Leer más
                          
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              </GridItem>
        )
      })

    )
  }
  return (
    <div>
      <Hidden mdUp>
          <div style={{ marginLeft:"-15px", display: "flex", alignItems:"center",  marginTop:"-30px", marginBottom:"-20px" /*the negative is to reduce gap between navbar and this component. Because of GridItem styles*/}}>
            <Icon>cake</Icon>
            <h4 style={{paddingLeft:"5px", fontFamily:"IndivisaTextSansBold", color:"black"}}>Aniversarios - {(viewType === 1 ? ("Votos temporales") : (viewType === 2 ? "Votos perétuos" : "Toma de hábito"))}</h4>
          </div>
      </Hidden>
      
        
      
      <GridContainer> 
        
        {data.length > 0 ? ( renderHermanos() ) : 
        (
            <GridItem xs={12} sm={12} md={12} >

              <Box  style={{display:"flex", alignItems:"center", justifyContent:"center", background:"white", borderRadius:"6px", fontSize:"18px", color:"black", fontFamily:"IndivisaTextSansBold", paddingBottom:"20px", paddingTop:"20px"}}> {"No se encontraron aniversarios para este dia"}</Box>
            </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
