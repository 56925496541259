import React from "react";
///core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Hidden from '@material-ui/core/Hidden';
import Info from '../../components/Typography/Info';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box'
import DateModal from 'components/Calendar/DateModal.js';
import myFunc, {getNextWeek, getPreviousWeek} from "components/Calendar/MiscFunctions";
import Emphasized from "components/Typography/Emphasized";
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
export default function Calendar(props)
{
    //var todaysDate = new Date();
    //var todaysDate = props.date;
    const { selectedDate, setSelectedDate } = props;
    
    var weekFetch = myFunc(selectedDate);
    
    const [currentIndex, setCurrentIndex] = React.useState(weekFetch.currentIndex)//theres no need to decrement it here, because I already receive it as 0 based
    const [currentWeek, setCurrentWeek] = React.useState(weekFetch.week);
    const [confirmedValue, setConfirmedValue] = React.useState(selectedDate);
    const [dateAbstract, setDateAbstract] = React.useState(
      {
        localDate: selectedDate,
        currentIndex: weekFetch.currentIndex,
        currentWeek: weekFetch.week,
      }
    );

    const setTodaysDate = () =>{
      var todaysDate = new Date();
      var week = myFunc(todaysDate);
      var index = (todaysDate.getDay() == 0 ? 6 : todaysDate.getDay() -1 )
      setDateAbstract({localDate:todaysDate, currentIndex: index, currentWeek: week.week});
      setSelectedDate(todaysDate);
    }

    const handleDateChangeInner = (date) => {
      var week = myFunc(date);
      setDateAbstract({localDate:date,currentIndex:week.currentIndex, currentWeek:week.week});
      setSelectedDate(date);//trigger root component re-render
      
    };
    
    const handleDaySwitcher = (side) =>{
      var selected;
      if(side === 1)//leftSide clicked
      {
        if(dateAbstract.currentIndex !== 0)
        {
          //selected = currentWeek[dateAbstract.currentIndex-1];
          selected = dateAbstract.currentWeek[dateAbstract.currentIndex-1]
          var week = myFunc(selected)
          setDateAbstract({localDate:selected, currentIndex: dateAbstract.currentIndex-1, currentWeek:week.week});
          /*setConfirmedValue(selected);
          setSelectedDate(selected);
          setCurrentIndex(currentIndex-1);*/
        }
        else{
          //gotta do a fresh fetch
          var freshWeek = getPreviousWeek(dateAbstract.localDate);
          setDateAbstract({localDate:freshWeek.week[6], currentIndex: 6, currentWeek:freshWeek.week});
          selected = freshWeek.week[6];
          /*
          var freshWeek = getPreviousWeek(selectedDate);
          setConfirmedValue(freshWeek.week[6]);
          setSelectedDate(freshWeek.week[6])
          setCurrentWeek(freshWeek.week);
          setCurrentIndex(6);*/
        }
      }
      else///rightSide clicked
      {
        //console.log("Al darle click derecha tengo localDate ->"+dateAbstract.localDate)
        if(dateAbstract.currentIndex !== 6)
        {
          //selected = currentWeek[dateAbstract.currentIndex+1];
          selected = dateAbstract.currentWeek[dateAbstract.currentIndex+1]
          var week = myFunc(selected);
          setDateAbstract({localDate:selected, currentIndex: dateAbstract.currentIndex+1, currentWeek:week.week})
          /*
          selected = currentWeek[currentIndex+1];
          setConfirmedValue(selected);
          setSelectedDate(selected);
          setCurrentIndex(currentIndex+1);*/

        }
        else{
          //gotta do a fresh fetch
          
          var freshWeek = getNextWeek(dateAbstract.localDate);
          selected = freshWeek.week[0]
          setDateAbstract({localDate:freshWeek.week[0], currentIndex: 0, currentWeek:freshWeek.week});
          
          /*var freshWeek = getNextWeek(selectedDate);
          setConfirmedValue(freshWeek.week[0]);
          setSelectedDate(freshWeek.week[0])
          setCurrentWeek(freshWeek.week);
          setCurrentIndex(0);*/
          

        }
      }
      setSelectedDate(selected)
    }

    const handleWeekDayClick = (indexClicked)=>{
      setSelectedDate(dateAbstract.currentWeek[indexClicked]);
      setDateAbstract({localDate:dateAbstract.currentWeek[indexClicked], currentIndex: indexClicked, currentWeek: dateAbstract.currentWeek})
      //trigger to root component
      
      /*setConfirmedValue(dateAbstract.currentWeek[indexClicked]);
      setCurrentIndex(indexClicked);
      setSelectedDate(currentWeek[indexClicked]);*/
    }

    const updateChildDates = (comingFrom) =>{
      /*if(comingFrom === 1)//entonces vengo del calendarModal
      {
        
        var week = myFunc(selectedDate);
        setCurrentIndex(week.currentIndex)
        setCurrentWeek(week.week);
      }*/
      if(comingFrom === 1)//entonces vengo del calendarModal
      {
        
        var week = myFunc(confirmedValue);
        setCurrentIndex(week.currentIndex)
        setCurrentWeek(week.week);
      }
    }



    function renderDaySwitcher(day, gridItemSize = 4){
      return (
        <GridItem md={gridItemSize} lg={gridItemSize} xl={gridItemSize} style={{ display:"flex", alignItems: "center"}}>
          <div style={{color:"black", background:"white", width:"100%", height:"100%", display:"flex", alignItems:"center", borderRadius:"6px",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>
          <Box flex="10%" textAlign="center" style={{cursor:"pointer",  display:"flex", justifyContent:"center", alignItems: "center"}} onClick={()=>handleDaySwitcher(1)} >
            <KeyboardArrowLeftIcon fontSize="large"/>
          </Box>
          <Box flex="80%" textAlign="center" style={{userSelect:"none", fontFamily:"IndivisaTextSansBold"}}>
              {dateAbstract.localDate.toLocaleDateString("es-ES",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
          </Box>
          <Box flex="10%" textAlign="center" style={{cursor:"pointer", display:"flex", justifyContent:"center", alignItems: "center"}} onClick={()=>handleDaySwitcher(2)}>
            <KeyboardArrowRightIcon fontSize="large"/>
          </Box>
          </div>
        </GridItem>
      );
    }

    function renderWeek(week){
      
      var requiresEmphasized = (index, date, elementAcronym)=>{
        if(index === dateAbstract.currentIndex)
          return (
            <div style={{cursor:"pointer", color:"black", fontFamily:"IndivisaTextSansBold"}} onClick={()=>{handleWeekDayClick(index)}}>
              <Emphasized>{date}</Emphasized>
              <Emphasized>{elementAcronym}</Emphasized>
            </div>
          )
        else
        return (
          <div style={{cursor:"pointer", fontFamily:"inherit"}} onClick={()=>{handleWeekDayClick(index)}}>
              <Info>{date}</Info>
              <Info>{elementAcronym}</Info>
          </div>
        )
      
      }
      return (
        <GridItem md={6} lg={6} xl={6}>
          <div style={{height:"100%", display:"flex",   justifyContent:"center",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>
          
          
          {
            
            dateAbstract.currentWeek.map((element, index) =>{
              var elementAcronym;
              var dayIndex = element.getDay();
              var date = element.getDate();
              if(dayIndex === 1)
                elementAcronym="Lun";
              else if(dayIndex === 2)
                elementAcronym="Mar";
              else if(dayIndex === 3)
                elementAcronym="Mie";
              else if(dayIndex === 4)
                elementAcronym="Jue";
              else if(dayIndex === 5)
                elementAcronym="Vie";
              else if(dayIndex === 6)
                elementAcronym="Sab";
              else
                elementAcronym="Dom";
              if(index === 0)
                return (
                  <Box key={index} flex="11%" textAlign="center" style={{ display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none", background: "white", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px"}}>
                    {/*
                    <Info onMouseOver={(event)=>event.preventDefault()}>{date}</Info>
                    <Info onMouseOver={(event)=>event.preventDefault()}>{elementAcronym}</Info>
                    */}
                    {
                    requiresEmphasized(index,date,elementAcronym)
                    }
                  </Box>
                )
              if(index === 6)
                  return (
                    <Box key={index} flex="11%" textAlign="center" style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none", background: "white", borderTopRightRadius: "6px", borderBottomRightRadius: "6px"}}>
                    {
                      requiresEmphasized(index,date,elementAcronym)
                    }
                    </Box>
                  )
              return (
                <Box key={index} flex="11%" textAlign="center" style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none", background: "white"}} >
                  {
                      requiresEmphasized(index,date,elementAcronym)
                  }
                </Box>
              );
            })}
          </div>
        </GridItem>
      )
    }

    function renderMobileWeek(week){
      var requiresEmphasized = (index, date, elementAcronym)=>{
        if(index === dateAbstract.currentIndex)
          return (
            <div style={{color:"black", fontFamily:"IndivisaTextSansBold"}}  onClick={()=>{handleWeekDayClick(index)}}>
              <Emphasized>{date}</Emphasized>
              <Emphasized>{elementAcronym}</Emphasized>
            </div>
          )
        else
        return (
          <div style={{fontFamily:"inherit"}}  onClick={()=>{handleWeekDayClick(index)}}>
              <Info>{date}</Info>
              <Info>{elementAcronym}</Info>
          </div>
        )
      
      }
      return (
        <GridItem xs={12} sm={12}>
          <div style={{height:"100%", display:"flex",   justifyContent:"center",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>
        {
          dateAbstract.currentWeek.map( (element, index)=>{
            var elementAcronym;
            var dayIndex = element.getDay();
            var date = element.getDate();
            if(dayIndex === 1)
              elementAcronym="Lun";
            else if(dayIndex === 2)
              elementAcronym="Mar";
            else if(dayIndex === 3)
              elementAcronym="Mie";
            else if(dayIndex === 4)
              elementAcronym="Jue";
            else if(dayIndex === 5)
              elementAcronym="Vie";
            else if(dayIndex === 6)
              elementAcronym="Sab";
            else
              elementAcronym="Dom";
            if(index === 0)
              return (
                <Box key={index} flex="14%" textAlign="center" style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none", background: "white", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px"}}>
                  {
                    requiresEmphasized(index,date,elementAcronym)
                  }
                </Box>
              )
            if(index === 6)
                return (
                  <Box key={index} flex="14%" textAlign="center" style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none", background: "white", borderTopRightRadius: "6px", borderBottomRightRadius: "6px"}}>
                  {
                    requiresEmphasized(index,date,elementAcronym)
                  }
                  </Box>
                )
            return (
              <Box key={index} flex="14%" textAlign="center" style={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"3px", paddingBottom:"3px", userSelect:"none",background: "white"}} >
                {
                    requiresEmphasized(index,date,elementAcronym)
                }
              </Box>
            );
        })}   
        </div>
        </GridItem>
      );
    };

    return (
      <div>
      {/* <Hidden only={['md','lg', 'xl']}> */}
      <Hidden mdUp>
        
      <GridContainer>
          {renderMobileWeek(currentWeek)}
          <GridItem xs={8} sm={8} style={{ display:"flex", alignItems: "center"}}>
            <div style={{width:"100%", height:"100%", padding:"5px 0px", display:"flex", alignItems:"center", background:"white", borderRadius:"6px",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>
              <Box flex="10%" textAlign="center" style={{cursor:"pointer", color:"black", display:"flex", alignItems: "center", justifyContent:"center"}} onClick={()=>handleDaySwitcher(1)}>
                <KeyboardArrowLeftIcon fontSize="large"/>
              </Box>
              <Box flex="80%" style={{cursor:"context-menu"}}>
              
              <DateModal
                  
                  handleChange={handleDateChangeInner}
                  setSelected={setSelectedDate}
                  date={dateAbstract.localDate}
              />
              </Box>
              
              <Box flex="10%" textAlign="center" style={{cursor:"pointer", color:"black", display:"flex", alignItems: "center", justifyContent:"center"}} onClick={()=>handleDaySwitcher(2)}>
                <KeyboardArrowRightIcon fontSize="large"/>
              </Box>
            </div>
          </GridItem>
          <GridItem xs={2} sm={2} style={{ display:"flex", alignItems: "center"}}>
            <div style={{width:"100%",height:"100%", padding:"5px 0px", display:"flex", justifyContent:"center", background:"white", alignItems:"center", borderRadius:"6px",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"  }}>
              <Box flex="80%" style={{ display:"flex",justifyContent:"center"}}>
                <DateModal
                    handleChange={handleDateChangeInner}
                    setSelected={setSelectedDate}
                    date={dateAbstract.localDate}
                    justIcon={true}
                />
              </Box>
            </div>
          </GridItem>
          <GridItem xs={2} sm={2} style={{ display:"flex", alignItems: "center"}}>
            <div style={{cursor:"pointer", width:"100%",height:"100%", padding:"5px 0px", display:"flex", justifyContent:"center", background:"white", alignItems:"center", borderRadius:"6px",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"  }} onClick={()=>{setTodaysDate()}}>
              <Box flex="80%" style={{ display:"flex",justifyContent:"center"}}>
                
                <span style={{
                  fontWeight: "bold",
                  fontSize: "18px"
                }}>HOY</span>
              </Box>
            </div>
          </GridItem>
        </GridContainer>
        
      </Hidden>
      
        {/* 
        <Hidden only={['xs','sm']}> */}
        <Hidden smDown>
        
            <GridContainer>
            {renderWeek(currentWeek)}
          
          <GridItem md={1} lg={1} xl={1}>
            <div style={{width:"100%",height:"100%", display:"flex", justifyContent:"center", background:"white", alignItems:"center", borderRadius:"6px",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"  }}>
            <Box flex="80%" style={{ display:"flex",justifyContent:"center"}}>
              <DateModal
                  handleChange={handleDateChangeInner}
                  setSelected={setSelectedDate}
                  date={dateAbstract.localDate}
              />
            </Box>
            </div>
          </GridItem>
          <GridItem md={1} lg={1} xl={1}>
            <div style={{width:"100%",height:"100%", display:"flex", justifyContent:"center", background:"white", alignItems:"center", borderRadius:"6px", cursor:"pointer",boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"  }} onClick={()=>{setTodaysDate()}}>
                <Box flex="80%" style={{ display:"flex",justifyContent:"center"}}>
                <span style={{
                  fontWeight: "bold",
                  fontSize: "18px"
                }}>HOY</span>
                </Box>
            </div>
          </GridItem>
          { renderDaySwitcher(dateAbstract.localDate)}
          </GridContainer>
        </Hidden>
        </div>
    );
}