var withCredentialsState = false;
var apiURL = "https://dial.lasalleca.net/api/jsonapi/";
var apiURL2 = "https://dial.lasalleca.net/api/rest/";
var apiURL3 = "https://dial.lasalleca.net/api/glifos/api/";
var apiURL3_1 = "https://dial.lasalleca.net/api/gsearch/api/";
var logoutRoute = "https://dial.lasalleca.net/api/glifos/logout";
var loginRoute = "https://dial.lasalleca.net/api/user/login/google";
var apiHome = "https://dial.lasalleca.net";




module.exports ={
    apiURL,
    apiURL2,
    apiURL3,
    apiURL3_1,
    apiHome,
    logoutRoute,
    loginRoute
};