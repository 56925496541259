import {yellowColor} from "assets/jss/material-dashboard-react.js";
const indicatorStyle = (theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        
      },
    indicator:{
        color:yellowColor[0]
    }
});

export default indicatorStyle;