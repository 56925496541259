import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import HeadsetIcon from '@material-ui/icons/Headset';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Icon from '@material-ui/core/Icon';
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
//test
import Hidden from '@material-ui/core/Hidden';

import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiURL, apiURL3, apiURL3_1, withCredentialsState } from "variables/restApi";
import { GridList, GridListTile } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
	//console.log(props);
	const classes = useStyles();
	const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] });

	React.useEffect(() => {


		axios.get(apiURL3_1 + "search_cancionlasallecanta/result_cancionlasallecanta?type=json", { headers: { Accept: 'text/html; charset=UTF-8' } }).then((res) => {
			var realRep = JSON.parse(res.data.replace(/\n/g, "").replace(/\t/g, ""));
			setDataRep({ isLoading: false, data: realRep.data });
		}).catch((error) => {
			console.log(error);
		})

	}, []);

	function renderSongs() {
		if (dataRep.isLoading) {
			return (
				<GridItem xs={12} sm={12} md={12} lg={12} style={{ display: "flex", marginTop: "30px", justifyContent: "center" }}>
					<LoadingIndicator />
				</GridItem>
			)
		}

		return (
			dataRep.data.map((element, index) => {
				//element = element.attributes;
				return (
					<GridItem key={index} xs={12} sm={12} md={6} >
						<Link to={{
							pathname: "/element", state: {
								type: 7,
								title: element.attributes.title,
								//childs: ["1 Corintios 3, 1-9:", "Nosotros somos colaboradores de Dios, y ustedes campo de Dios, edificio de Dios", "Salmo responsorial 32:", "Dichoso el pueblo que el Señor se escogió como heredad", "1 Lucas 4, 38-44:", "Tambien a los otros pueblos tengo que anunciarles el Reino de Dios, para eso me han enviado" ],
								obj: element,
								icon: "headset",
								iconColor: "info",

							}
						}} style={{ color: "inherit", background: "blue" }}>

							<div style={{ height: "100%", color: "black", background: "white", display: "flex", alignItems: "center", borderRadius: "6px", boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)" }}>

								<div style={{ flex: "10%", display: "flex", padding: "10px 0px", alignItems: "center", paddingLeft: "10px" }}>
									<HeadsetIcon></HeadsetIcon>
								</div>
								<div style={{ fontFamily: "IndivisaTextSansBold", flex: "80%", display: "flex", padding: "10px 0px", alignItems: "center" }}>
									{element.attributes.title}
								</div>
								<div style={{ flex: "10%", display: "flex", padding: "10px 0px", alignItems: "center" }}>
									<ArrowForwardIcon></ArrowForwardIcon>
								</div>
							</div>
						</Link>

					</GridItem>
				)
			})
		)
	}

	return (
		<div>
			{/*
			<Hidden mdUp>
				<div style={{ marginLeft:"-15px", display: "flex", alignItems:"center",  marginTop:"-30px", marginBottom:"-20px" //the negative is to reduce gap between navbar and this component. Because of GridItem styles
				}}>
					<Icon style={{paddingLeft:"2px"}}>library_music</Icon>
					<h4 style={{paddingLeft:"5px", fontFamily:"IndivisaTextSansBold", color:"black"}}>La Salle Canta</h4>
				</div>
			</Hidden>
			*/}
			<GridContainer spacing="1">
				{renderSongs()}
			</GridContainer>
		</div>
	);
}
