import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from 'clsx';

import { makeStyles } from "@material-ui/core/styles";
import PageNavigator from "components/PageNavigator/PageNavigator";
import {apiURL3} from "variables/restApi";
import { Link} from "react-router-dom";
import axios from "axios";
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { grayColor } from "assets/jss/material-dashboard-react.js";
import useDebounce from 'components/valueDebouncer/debouncer.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
const styles = {
    customTitle:{
        fontFamily: "IndivisaTextSansBold",
        color:"black",
        margin: "0",
        fontSize: "18px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardCategory: {
        color: grayColor[0],
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    emphasizedText:{
        fontSize:"16px",
        "& et":{
          fontFamily:"IndivisaTextSansBold",
          color:"black"
        }
      },
      selectItem:{
        textAlign:"center", 
        fontFamily:"inherit", 
        fontSize:"18px"
      },
      dropDown:{
        color:"black",
        fontSize:"18px", 
        display:"flex", 
        alignItems:"center", 
        textAlign:"center", 
        justifyContent:"center", 
        background:"white", 
        paddingTop:"10px", 
        paddingBottom:"15px", 
        borderRadius:"6px"
      }
};
const useStyles = makeStyles(styles);
const useStylesExpansionPanel = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));
  
  const useStylesPanel = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function getPagginatorIndex()
{
    var previousState = sessionStorage.getItem('str');
    if(previousState === null)
    {
        
        //sessionStorage.setItem('str',previousDate);
        sessionStorage.setItem('str',JSON.stringify({date:new Date(), med: 1, her: 1}));
        return 1;
    }
    else
    {
        previousState = JSON.parse(sessionStorage.getItem('str'));
        return previousState.med;
    }
}

const tiposMeditacion = [
    {display:"Meditaciones para domingo", value:"md"},
    {display:"Meditaciones para retiro", value:"mr"},
    {display:"Meditaciones para días festivos", value:"mf"}
];

export default function Meditaciones()
{
    

    const classes = useStyles();
    const classesExpasionPanel = useStylesExpansionPanel();
    const classesPanel = useStylesPanel();
    const [dataRep, setDataRep] = React.useState({isLoading:true, data:[]});
    const [selectedElement, setSelectedElement] = React.useState();
    const [expanded, setExpanded] = React.useState(false);
    const [noMeditacion, setNoMeditacion] = React.useState('');
    const [tipoMeditacion, setTipoMeditacion] = React.useState();
    const [santo, setSanto] = React.useState();

    
    const [currentIndex, setCurrentIndex] = React.useState(getPagginatorIndex());
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    var debouncedValue = useDebounce(currentIndex, 500);
    var debouncedSanto = useDebounce(santo, 500);
    var debouncedNoMeditacion = useDebounce(noMeditacion, 500);
    const handleSelectedChange = (selected)=>{
        
        axios.get(apiURL3+"meditacion/"+selected.numero).then((res)=>{
            
            setDataRep({isLoading:false, data:res.data});
            setSelectedElement(selected);
        }).catch((error)=>{
            console.log(error);
        })
    }
    
    React.useEffect(()=>{
        if(debouncedValue === 0)
            debouncedValue = 1;

        {
            if(debouncedValue === 0)
                debouncedValue = 1;        
            axios.get(apiURL3+"meditacionesFiltro/"+debouncedValue + "?santo="+debouncedSanto + "&nm="+noMeditacion + "&tipo=" + tipoMeditacion).then((res)=>{
                var currentState = JSON.parse(sessionStorage.getItem('str'))
                currentState.med = +debouncedValue;
                sessionStorage.setItem('str',JSON.stringify(currentState));
                setDataRep({isLoading:false, data:res.data});
            }).catch((error)=>{
                console.log(error);
            });
        }
        /*
        axios.get(apiURL3+"meditaciones/"+debouncedValue).then((res)=>{
            
            var currentState = JSON.parse(sessionStorage.getItem('str'))
            currentState.med = +debouncedValue;
            sessionStorage.setItem('str',JSON.stringify(currentState));
            //console.log(res);
            setDataRep({isLoading:false, data:res.data});
        }).catch((error)=>{
            console.log(error);
        })*/
        /*
        var fakeData = [{"tipo":"MD","numero":"1"},{"tipo":"MD","numero":"2"},{"tipo":"MD","numero":"3"},{"tipo":"MD","numero":"4"},{"tipo":"MD","numero":"5"},{"tipo":"MD","numero":"6"},{"tipo":"MD","numero":"7"},{"tipo":"MD","numero":"8"},{"tipo":"MD","numero":"9"},{"tipo":"MD","numero":"10"},{"tipo":"MD","numero":"11"},{"tipo":"MD","numero":"12"},{"tipo":"MD","numero":"13"},{"tipo":"MD","numero":"14"},{"tipo":"MD","numero":"15"},{"tipo":"MD","numero":"16"},{"tipo":"MD","numero":"17"},{"tipo":"MD","numero":"18"},{"tipo":"MD","numero":"19"},{"tipo":"MD","numero":"20"}];
        console.log(fakeData);
        setDataRep({isLoading:false, data:fakeData});*/
    
    
    }, [debouncedValue, debouncedNoMeditacion, debouncedSanto, tipoMeditacion]);
    
    function renderSelectedElement()
    {
        
        var notasAlPie = (element)=>{
            if (element.field_notas_al_pie.length === 0)
                return null;
            return (
                <div>
                    <p className={classes.emphasizedText}>
                        <et is="custom">{"Notas al pie"}</et>
                    </p>
                    {
                        element.field_notas_al_pie.map((nota,index)=>{
                            return(
                                <div>
                            <div style={{fontSize:"16px"}}  dangerouslySetInnerHTML={{__html:nota.field_descripcion_nota_al_pie}}>

                            </div><br/></div>)
                        })
                    }
                </div>
            )
        };
        return ( 
                dataRep.data.map((element, index)=>{
                return (
                    
                    <GridItem key={index} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Card>
                        <CardHeader stats icon>
                            <CardIcon color="info">
                                <Icon>local_library</Icon>
                            </CardIcon>
                            <p className={classes.customTitle}>{element.title[0].value}</p>
                        </CardHeader>
                        <CardBody notFixedHeight>
                        {element.field_descripcion_advertencia_te.length > 0 ? (
                        <div>
                            <p className={classes.emphasizedText}>
                                <et is="custom">{"Descripción"}</et>
                            </p>
                            <div style={{ fontSize:"16px"}} dangerouslySetInnerHTML={{__html:element.field_descripcion_advertencia_te[0].value}}>
                            </div>
                        </div>) : null
                        }
                        <div>
                            <p className={classes.emphasizedText}>
                                <et is="custom">{"Meditacion"}</et>
                            </p>
                            <div style={{fontSize:"16px"}} dangerouslySetInnerHTML={{__html:element.field_meditaciones[0].value}}/>
                            <br/>
                        </div>
                        {
                            notasAlPie(element)
                        }
                        </CardBody>
                        </Card>
                    </GridItem>
                    
                    
                )
            }
        )
    );
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const mostrarTiposMeditacion = () => {
        return(
            tiposMeditacion.map((item) => {
              return(
                <MenuItem  value={ item.value } key={item.value } className={classes.selectItem}>
                  {item.display}
                </MenuItem>
              );
            }
          )
        );
    };

    function renderMeditaciones()
    {
        return ( 
                dataRep.data.map((element, index)=>{
                var meditationTitle = element.tipo + element.numero;
                return (
                    
                    <GridItem key={index} item item xs={12} sm={12} md={6} style={{ paddingBottom: "10px"}} onClick={()=>{handleSelectedChange(element);}}>
                        <Link to={{pathname: "/element", state:{
                            type: 12,
                            title: meditationTitle,
                            //childs: ["1 Corintios 3, 1-9:", "Nosotros somos colaboradores de Dios, y ustedes campo de Dios, edificio de Dios", "Salmo responsorial 32:", "Dichoso el pueblo que el Señor se escogió como heredad", "1 Lucas 4, 38-44:", "Tambien a los otros pueblos tengo que anunciarles el Reino de Dios, para eso me han enviado" ],
                            obj: element,
                            icon: "headset",
                            nid: element.numero,
                            iconColor: "info",
                            dynamicFont:true
                            
                        }}} style={{color:"inherit"}}>
                        
                            <div style={{ color:"black", background:"white", display: "flex", alignItems:"center", justifyContent:"center", borderRadius: "6px", boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"}}>
                                <div style={{ fontFamily:"IndivisaTextSansBold", padding:"10px 10px", userSelect:"none"}}>
                                    {meditationTitle}
                                </div> 
                            </div>
                        </Link>
                    </GridItem>
                    
                )
            }
        )
    );
    }
    return (
        <div>
            <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{paddingBottom: isMobileDevice  ? "20px" : "0px"}}>
                    <Paper component="div" className={""} elevation={0}>
                    
                    <div className={classesExpasionPanel.root} style={{fontFamily:"inherit", boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)", borderRadius:'6px'}}>    
                            <Paper elevation={0} 
                            style={{
                                display: "flex",
                                flexDirection: "row"
                            }}
                            >  
                                <IconButton
                                onClick={
                                    (event) => {
                                    setTipoMeditacion("undefined");
                                    setCurrentIndex(1);
                                    setNoMeditacion("undefined");
                                    setSanto("undefined");                              
                                    }
                                }
                                >
                                <ClearIcon style={{color:'black'}}/>
                                </IconButton>  
                                <IconButton className={""} aria-label="search" onClick={
                                (event) => {setTipoMeditacion(tipoMeditacion)}
                                }>
                                <SearchIcon style={{color:'black'}}/>
                                </IconButton>

                                <InputBase
                                className={"search-input"}
                                placeholder="Buscar por santo"
                                inputProps={{ 'aria-label': 'Buscar por santo' }}
                                onChange={ event => setSanto(event.target.value.toLowerCase()) }
                                style={{fontFamily:"inherit"}}
                                />
        

                                <IconButton
                                    className={clsx(classesPanel.expand, {
                                        [classesPanel.expandOpen]: expanded,
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                <ExpandMoreIcon style={{color:'black'}}/>
                            </IconButton> 
                            </Paper>       
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <div className={"filters"}>
                                    <Typography >
                                    <div className={classes.dropDown}> 
                                    <FormControl
                                        style={{
                                            width: "96%"
                                        }}
                                    >                       
                                        <InputLabel id="Meditacion" style={{fontFamily:"inherit"}} >Tipo de meditación</InputLabel>
                                        <Select
                                            labelId="Meditacion"
                                            value={tipoMeditacion}
                                            displayEmpty
                                            onChange={event => setTipoMeditacion(event.target.value)}
                                            input={<Input />}
                                            MenuProps={MenuProps}
                                            style={{width:"98%", fontSize:"18px"}}
                                            >
                                            <MenuItem  value={"s"} key={"seleccionar"} disabled className={classes.selectItem}>Seleccionar</MenuItem>
                                            {mostrarTiposMeditacion()}
                                        </Select>              
                                    </FormControl>
                                    </div>
                                    </Typography>

                                    <Typography style={{fontFamily:"IndivisaTextSansRegular"}}>
                                    <div className={classes.dropDown}>   
                                    <FormControl 
                                        style={{
                                        width: "96%",
                                        paddingBottom:"10px",
                                        fontFamily:"IndivisaTextSansRegular"
                                        }}
                                    >                              
                                        <TextField labelId="Num" label="Número meditación"   style={{fontFamily:"IndivisaTextSansRegular"}}                                   
                                            onChange={ event => setNoMeditacion(event.target.value.toLowerCase()) 
                                        }
                                        />
                                        
                                        
                                        
                                    </FormControl>         
                                    </div>
                                    </Typography>
                                </div> 
                            </Collapse>

                    </div>
                    </Paper>
                </GridItem> 
            </GridContainer>
            <PageNavigator
                setPage={setCurrentIndex}
                rootIndex={currentIndex}
                maxValue={11}
            />
            <GridContainer spacing="1">
            {
                dataRep.isLoading ? 
                    ( <GridItem xs={12} sm={12} md={12} lg={12} style={{display:"flex", marginTop:"30px", justifyContent:"center"}}><LoadingIndicator/></GridItem> ) 
                    : 
                    ( renderMeditaciones())
            }
                {/*selectedElement === undefined ? renderMeditaciones() : renderSelectedElement()*/}
            </GridContainer>
            
        </div>
    );
}
