import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-react/components/loadingIndicatorStyle.js';
import {yellowColor} from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles(styles);

export default function LoadingIndicator(props){
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.indicator} ></CircularProgress>
        </div>
    )
}