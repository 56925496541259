const cardBodyStyle = {
  cardBody: {
    padding: "10px 15px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    ///height: Set fixed height here, so all cards will have a body with fixed height 
    //height: "200px"
    height:"250px",
    overflow: "hidden" //to avoid text content overflowing content pane
  },
  cardBodyNotFixedHeight: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    //overflow: "hidden" //to avoid text content overflowing content pane
  },
  cardBodyPlain: {
    paddingLeft: "1px",
    paddingRight: "5px"
  },
  cardBodyProfile: {
    marginTop: "15px"
  }
};

export default cardBodyStyle;
