import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-react.js";

const dashboardStyle = {
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },

  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    
    fontSize:"14px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardFooterStats: {
    borderTop: "1px solid " + grayColor[10],
    marginTop: "20px",
    "& svg": {
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "16px",
      height: "16px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      fontSize: "16px",
      position: "relative",
      top: "4px",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  customTitle:{
    fontFamily: "IndivisaTextSansBold",
    color:"black",
    margin: "0",
    fontSize: "18px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
    "& ct":{
      fontFamily:"IndivisaTextSansRegularItalic",
      fontSize:"16px",
      color:"black"
    }
  },
  lectureText:{
    fontFamily: "IndivisaTextSansBold",
    color:"black",
    fontSize:"18px",
    "& ct":{
      fontFamily:"IndivisaTextSansRegularItalic",
      fontSize:"16px",
      color:"black"
    }
  },
  emphasizedText:{
    fontSize:"16px",
    "& et":{
      fontFamily:"IndivisaTextSansBold",
      color:"black"
    }
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none"
  }
  ///Start audio player related
  ,playIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'black'
    }
  },
  pauseIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'black'
    }
  },
  volumeIcon: {
    color: 'black',
    '&:hover': {
      color: 'black'
    }
  },
  volumeSlider: {
    color: 'black'
  },
  progressTime: {
    color: 'black'
  },
  mainSlider: {
    color: 'black',
    '& .MuiSlider-rail': {
      color: 'black'
    },
    '& .MuiSlider-track': {
      color: 'black'
    },
    '& .MuiSlider-thumb': {
      color: 'black'
    }
  }
  //End audio player related
};

export default dashboardStyle;
