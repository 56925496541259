import axios from 'axios';
import {apiURL2, withCredentialsState} from "variables/restApi.js";
const webpageRootDomain = "https://dial.lasalleca.net";
export default function isLoggedIn()
{
    //testing purposes
    //return true;
    
    var sessionVar = sessionStorage.getItem('usr');
    if(sessionVar === null || JSON.parse(sessionVar).uid === "0")
        return false;
    else
        return true;

}

export function logout()
{
    console.log("Loggin user out");
    sessionStorage.removeItem('usr');//gotta clean it up
}

export async function doCheckForAuthResult()
{
    var currentLoc = window.location+"";
    //if(currentLoc === webpageRootDomain || currentLoc === (webpageRootDomain+"/"))
    //{//because when succesfully logged from google auth, it redirects to webpage root domain, this way it doesnt query the api eveytime the user goes to homepage (/calendario)
        var retVal = await axios.get(apiURL2+"current_user", {withCredentials:true});
        
        if(retVal.data[0].uid !== "0")
        {///means the user has succesfully completed the authentication process
            sessionStorage.setItem('usr', JSON.stringify(retVal.data[0]));//store in sessionStorage
            return true;
        }
        else
            return false;
    //}
    //else
        return //isLoggedIn();
}
