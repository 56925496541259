import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";
///Calendar modal
import Modal from '@material-ui/core/Modal';
import IconButton from "@material-ui/core/IconButton";
///Core components
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
//import IconButton from '@material-ui/core/Icon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemText from "@material-ui/core/ListItemText";
//Icons
import { yellowColor } from "assets/jss/material-dashboard-react.js";
import {apiHome} from "variables/restApi.js";
import {logoutRoute} from "variables/restApi.js";
import {getWeekDelimitersAsString} from "components/Calendar/MiscFunctions";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import {logout} from "services/UserService";
import AccountCircleIcon from 'icons/AccountCircleIcon';
const useStyles = makeStyles(styles);
export default function UserInfoModal(props) {
  //console.log(props);
  var currentDay = new Date();
  var weekDelimiters = getWeekDelimitersAsString();
  const date = new Date();

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const {userRep} = props;
  const [open, setOpen] = React.useState(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState(date);
  const [selectedEndDate, setSelectedEndDate] = React.useState(date);
  const [openEndDate, setOpenEndDate] = React.useState(false);
  const [openStartDate, setOpenStartDate] = React.useState(false);
  
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    //console.log("Invoking handlClose")
    setOpen(false);
  };

  const body = (
    <ClickAwayListener onClickAway={handleClose}>
    
      <div style={{borderRadius:"6px", color:"white", background:"#004B92", paddingTop:"10px", paddingBottom:"10px", paddingLeft:"15px", paddingRight:"15px"}}>
          <div style={{display:"flex", justifyContent:"center"}}>
          { userRep.user_picture_2 != "" ? 
            (<img style={{width:"50px", height:"50px", borderRadius:"30px"}} src={ (apiHome+(userRep.user_picture_2))}></img>) 
            :
            /*  
            <Icon fontSize="large" style={{color:"black"}}>account_circle</Icon>
            */
            <AccountCircleIcon fontSize="large" style={{color:"black"}}/>

          }
          </div>
          <div style={{textAlign:"center", fontSize:"16px"}}>
            {userRep.name}
            <br/>
            {userRep.mail}
            <br/>
            <a href={logoutRoute} onClick={()=>logout()} style={{color:'inherit'}}>
            <div style={{fontFamily:"IndivisaTextSansBold", fontSize:"18px", borderRadius:"6px", background:yellowColor[0], width:"fitContent", marginTop:"10px", paddingTop:"10px", paddingBottom:"10px"}}>
              Salir
            </div>
            </a>
          </div>
      </div>
    
    </ClickAwayListener>
  );
  //console.log("Date es ->"+date);
  return (
    <div onClick={handleOpen} style={{display:"flex"}}>
      {/*
      <Icon className={classes.icons}>account_circle</Icon>
      */}
      <AccountCircleIcon/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{display:'flex', alignItems:'center',justifyContent:'center'}}
      >
      {body}
      </Modal>
    </div>
    
  );
}
