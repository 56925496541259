import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames";
///Calendar modal
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
///Core components
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemText from "@material-ui/core/ListItemText";
//Icons
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Button from "components/CustomButtons/Button.js";
import { getWeekDelimitersAsString } from "components/Calendar/MiscFunctions";
import { beautifyDate } from "components/Calendar/MiscFunctions";
import DateModal from 'components/Calendar/DateModal';
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";



const useStyles = makeStyles(styles);
export default function PrintModal(props) {
	//console.log(props);
	var currentDay = new Date();
	var weekDelimiters = getWeekDelimitersAsString();
	const date = new Date();

	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [open, setOpen] = React.useState(false);
	const [selectState, setSelectState] = React.useState(false);///cerrado por default
	const [selectedValue, setSelectedValue] = React.useState('Selecciona un mes');
	const [selectedStartDate, setSelectedStartDate] = React.useState(date);
	const [selectedEndDate, setSelectedEndDate] = React.useState(date);
	const [openEndDate, setOpenEndDate] = React.useState(false);
	const [openStartDate, setOpenStartDate] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = (event) => {
		setOpen(false);
	};


	const printCalendar = (option, mes = 1) => {
		//console.log("Calendar option selected"+option);
		handleClose()
		if (option === 3) {
			window.open("http://api-dial.lasalleca.net/pdf/generate/month/" + mes + "/"+new Date().getFullYear(), "_blank");//(currentDay.getMonth()+1)+"/"+currentDay.getFullYear(),"_blank");
		}
	};

	const body = (
		<ClickAwayListener onClickAway={handleClose}>
			<div style={{ borderRadius: "6px", color: "white", background: "#004B92" }}>
				<div style={{ margin: "20px" }}>
					<div >
						{/* COMMENTED BECAUSE THOSE CALENDAR ROUTES ARENT READY, SO UNCOMMENT THESE BLOCKS WHEN ITS READY
            <div style={{textAlign:"center"}}>
              <Button variant="contained" style={{fontFamily:"inherit", width:"100%"}} onClick={() => printCalendar(1)}>Hoy<br/>{currentDay.toLocaleDateString("es-ES",{month: 'long', day: 'numeric' })}</Button>
            </div>
            <div style={{textAlign:"center"}}>
            <br/>
              <Button variant="contained" style={{fontFamily:"inherit"}} onClick={() => printCalendar(2)}>Esta semana <br/>{weekDelimiters}</Button>
            </div>
          */}
						{/*
            <div style={{ display:"flex", flexDirection:"column", alignContent:"center" }}>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,1)}>Enero 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,2)}>Febrero 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,3)}>Marzo 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,4)}>Abril 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,5)}>Mayo 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,6)}>Junio 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,7)}>Julio 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,8)}>Agosto 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,9)}>Septiembre 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,10)}>Octubre 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,11)}>Noviembre 2021</Button>
            <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", }} onClick={() => printCalendar(3,12)}>Diciembre 2021</Button>
            

            </div>
            */}
			<div style={{display:'flex', flexDirection:'column'}}>
				<div style={{paddingTop:'20px'}}>
				<Select
					id={'select'}
					open={selectState}
					onClose={()=>setSelectState(false)}
					onOpen={()=>setSelectState(true)}
					value={selectedValue}
					onChange={(event)=>setSelectedValue(event.target.value)}
					MenuProps={{ disablePortal: true }}
					style={{width:'200px', color:'white', fontFamily:'IndivisaTextSansBold', textAlign:'center', fontSize:'16px'}}
				>
					<MenuItem value="Selecciona un mes" style={{fontFamily:'IndivisaTextSansBold'}}>Selecciona un mes</MenuItem>
					<MenuItem value={1} style={{fontFamily:'IndivisaTextSansBold'}}>Enero</MenuItem>
					<MenuItem value={2} style={{fontFamily:'IndivisaTextSansBold'}}>Febrero</MenuItem>
					<MenuItem value={3} style={{fontFamily:'IndivisaTextSansBold'}}>Marzo</MenuItem>
					<MenuItem value={4} style={{fontFamily:'IndivisaTextSansBold'}}>Abril</MenuItem>
					<MenuItem value={5} style={{fontFamily:'IndivisaTextSansBold'}}>Mayo</MenuItem>
					<MenuItem value={6} style={{fontFamily:'IndivisaTextSansBold'}}>Junio</MenuItem>
					<MenuItem value={7} style={{fontFamily:'IndivisaTextSansBold'}}>Julio</MenuItem>
					<MenuItem value={8} style={{fontFamily:'IndivisaTextSansBold'}}>Agosto</MenuItem>
					<MenuItem value={9} style={{fontFamily:'IndivisaTextSansBold'}}>Septiembre</MenuItem>
					<MenuItem value={10} style={{fontFamily:'IndivisaTextSansBold'}}>Octubre</MenuItem>
					<MenuItem value={11} style={{fontFamily:'IndivisaTextSansBold'}}>Noviembre</MenuItem>
					<MenuItem value={12} style={{fontFamily:'IndivisaTextSansBold'}}>Diciembre</MenuItem>
				</Select>
				</div>
				<div style={{width:'100%', paddingTop:'20px'}}>
					<Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"16px", width:'100%'}} onClick={() => !isNaN(+selectedValue) ? printCalendar(3,selectedValue) : undefined}>Imprimir</Button>
				</div>
			</div>
						{/*
            <br/>
              <Button variant="contained" style={{background:"#fda000", fontFamily:"IndivisaTextSansBold", fontSize:"18px", width:"100%"}} onClick={() => printCalendar(3)}>Este mes <br/>{currentDay.toLocaleDateString("es-ES",{month: 'long', year:'numeric'})}</Button>
            
            */}
					</div>
					<br></br>

					{/* COMMENTED BECAUSE THOSE CALENDAR ROUTES ARENT READY, SO UNCOMMENT THESE BLOCKS WHEN ITS READY
        <hr/>
        <p style={{textAlign:"center"}}>Rango de fechas</p>
        <div style={{display:"flex", alignItems:"center"}} onClick={()=>setOpenStartDate(true)}>
            <p style={{fontSize:'inherit', marginRight:'8px'}}>De: {beautifyDate(selectedStartDate)}</p>
            <CalendarTodayIcon fontSize="small"/>
        </div>
        <div style={{display:"flex", alignItems:"center", marginTop:"-20px"}} onClick={()=>setOpenEndDate(true)}>
            <p style={{fontSize:'inherit', marginRight:'8px'}}>Hasta: {beautifyDate(selectedEndDate)}</p>
            <CalendarTodayIcon fontSize="small"/>
        </div>
        */}


					{/*EndDate modal*/}
					{/* COMMENTED BECAUSE THOSE CALENDAR ROUTES ARENT READY, SO UNCOMMENT THESE BLOCKS WHEN ITS READY
        <DateModal 
                type="printModal"
                setSelected={setSelectedEndDate}
                date={selectedEndDate}
                customOpen = {openEndDate}
                customOpenCtrl = {setOpenEndDate}
        />*/}
					{/*StartDate modal*/}
					{/* COMMENTED BECAUSE THOSE CALENDAR ROUTES ARENT READY, SO UNCOMMENT THESE BLOCKS WHEN ITS READY}
        {/*
        <DateModal 
                type="printModal"
                setSelected={setSelectedStartDate}
                date={selectedStartDate}
                customOpen = {openStartDate}
                customOpenCtrl = {setOpenStartDate}
        />*/}

					{/* COMMENTED BECAUSE THOSE CALENDAR ROUTES ARENT READY, SO UNCOMMENT THESE BLOCKS WHEN ITS READY
        <div style={{textAlign:"center"}}>
            <Button variant="contained" style={{fontFamily:"inherit"}} onClick={() => printCalendar(4)}>Imprimir</Button>
        </div>
        */}
				</div>
			</div>
		</ClickAwayListener>
	);
	//console.log("Date es ->"+date);
	return (
		<div onClick={handleOpen} style={{ display: "flex" }}>
			<Icon className={classNames(classes.itemIcon)}>print</Icon>
			<ListItemText
				primary={"Imprime tu calendario"}
				className={classNames(classes.itemText)}
				disableTypography={true}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				{body}
			</Modal>
		</div>

	);
}
