/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch} from "react-router-dom";
///fonts
import "./assets/fonts/IndivisaTextSans-Regular.ttf";
import "./assets/fonts/IndivisaTextSans-Bold.ttf";
import "./assets/fonts/IndivisaTextSans-RegularItalic.ttf";


// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css";

const hist = createBrowserHistory();
hist.listen((location, action) => {
  window.scrollTo(0, 0);
  //console.log("Desde history.....");
});

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={Admin} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
