import React from "react";
import classNames from "classnames";
//react-router
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SvgIcon from '@material-ui/core/SvgIcon';
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Icon from '@material-ui/core/Icon';
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import PrintModal from "components/PrintModal/PrintModal";

const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const { sessionState, sessionCtrl } = props;
  const classes = useStyles();
  //const [loggedIn, setLoggedIn] = React.useState(true);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openDashBoard, setOpenDashBoard] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickDashBoard = event => {
    if (openDashBoard && openDashBoard.contains(event.target)){
      setOpenDashBoard(null);
    }
    else
      setOpenDashBoard(event.currentTarget);
  };
  const handleCloseDashBoard = () =>{
    setOpenDashBoard(null);
  }
  const handleClickLogin = event => {
    if (sessionState) {
      sessionCtrl(false);
    } else {
      sessionCtrl(true);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <div>
      
      {sessionState && (
      <div className={classes.manager}>
        <Link to={{pathname: "/login"}} style={{color:"inherit"}}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickLogin}
            className={classes.buttonLink}
          >
            {/*
            <Icon style={{fill:"white", stroke:"white"}}>
              <img src={icon}/>
            </Icon>*/}
                                    <SvgIcon>
                                    <svg
                                    aria-hidden="true"
                                    data-prefix="fab"
                                    
                                    fill="white"
                                    
                                    xmlns="http://www.w3.org/2000/svg"
                                    
                                    id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.4 144.3"
                                    >                                    
                                    <polygon class="cls-1" points="118.32 26.75 83.2 42.36 48.08 26.75 20.51 116.49 68.24 127.55 83.2 61.22 98.16 127.55 145.89 116.49 118.32 26.75"/>
                                    </svg>{" "}
                                  </SvgIcon>
            {/*No longer hidden
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Directorio de hermanos</p>
            </Hidden>
            */}
            <p className={classes.linkText}>Directorio de hermanos</p>
          </Button>
        </Link>
      </div> )}
      
      {sessionState && (
      <div className={classes.manager}>
        
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Icon className={classes.icons}>cake</Icon>
          
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Aniversarios
            </p>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                    <ListItemIcon >
                      <Icon fontSize="small">add_circle</Icon>
                    </ListItemIcon>
                    <Typography variant="inherit">Votos temporales</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                    <ListItemIcon >
                      <Icon fontSize="small">add_circle</Icon>
                    </ListItemIcon>
                    <Typography variant="inherit">Votos perpétuos</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                    <ListItemIcon >
                      <Icon fontSize="small">add_circle</Icon>
                    </ListItemIcon>
                    <Typography variant="inherit">Toma de hábito</Typography>
                    </MenuItem>
                    
                    
                  </MenuList>
                  
                  
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div> )}
      {sessionState && (
      <div className={classes.manager}>
        <PrintModal/>
      </div> )}
      <div className={classes.manager}>
        <Link to={{pathname: "/login"}} style={{color:"inherit"}}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickLogin}
            className={classes.buttonLink}
          >
            {!sessionState ?
            <ExitToAppIcon className={classes.icons}/> :
            <AccountCircleIcon className={classes.icons}/>
            }
              <p className={classes.linkText}>Cuenta</p>
          </Button>
        </Link>
      </div> 
    </div>
  );
}
//export default withRouter(AdminNavbarLinks);
export default AdminNavbarLinks;