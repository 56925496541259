const ThirtyDaysMonths = [3,5,8,10];
const ThirtyOneDaysMonths = [0,2,4,6,7,9,11];
const WeirdMonths = [1];

function isLeapYear(year)
{
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

export function getCurrentWeekAligned(date)
{

    //var isLeap = isLeapYear(date.getFullYear());
    //var dayIndex = date.getDay();
    //console.log("Recibiendo en getCurrentWeekAligned"+date);
    var monthIndex = date.getMonth();
    var dayOfTheMonth = date.getDate();
    var year = date.getFullYear();
    var week = getPreviusDaysAligned(dayOfTheMonth, year, monthIndex);
    //week.push({month:monthIndex, day: dayOfTheMonth});
    week.push(new Date(year, monthIndex, dayOfTheMonth));
    week = week.concat(getPrecedingDaysAligned(dayOfTheMonth, year, monthIndex));

    //console.log("Get current week new function");
    //console.log(getCurrentWeek(date));
    return week;
}

function getPrecedingDaysAligned(dayOfTheMonth, currentYear, currentMonth, precDays = 3)
{
    var rightItems = precDays;
    var precedingDays = [];
    var leapYear = isLeapYear(currentYear);
    for(var i = 1; i <= rightItems; i++)
    {
        var newDay = dayOfTheMonth + i;
        if (newDay > 30 && ThirtyDaysMonths.indexOf(currentMonth) !== -1)//this way we only fill preceding days from the same month!
        //    precedingDays.push({month:currentMonth+1, day: newDay - 30});
        {    
            /*
            console.log("ThirtyDays month en getPreceding")
            console.log("precDays ->"+precDays);
            console.log("date recibida"+dayOfTheMonth);*/
            currentMonth = currentMonth+1;
            precedingDays.push(new Date(currentYear, currentMonth, 1));
            dayOfTheMonth = 1;
            rightItems = rightItems - i;
            i = 0;
            
            
           
        }
        else if(newDay > 31 && ThirtyOneDaysMonths.indexOf(currentMonth) !== -1)//this way we only fill preceding days from the same month
        //    precedingDays.push({month:currentMonth+1, day: newDay - 31});
        {
            currentMonth = currentMonth +1;
            precedingDays.push(new Date(currentYear, currentMonth, 1));
            dayOfTheMonth = 1;
            rightItems = rightItems - i;
            i = 0;
            
        }
        else if(WeirdMonths.indexOf(currentMonth) !== -1)
        {
            if(leapYear)
            {
                if(newDay > 29)
                {
                //    precedingDays.push({month:currentMonth+1, day: newDay-29});
                    precedingDays.push(new Date(currentYear, currentMonth+1, 1));
                    dayOfTheMonth = 1;
                    currentMonth = currentMonth + 1;
                }
                else 
                    //precedingDays.push({month:currentMonth, day: newDay});
                    precedingDays.push(new Date(currentYear, currentMonth, newDay));
            }
            else
            {
                if(newDay > 28)
                {
                    //precedingDays.push({month: currentMonth+1, day:newDay-28});
                    precedingDays.push(new Date(currentYear, currentMonth+1, 1));
                    dayOfTheMonth = 1;
                    currentMonth = currentMonth +1;
                }
                else// precedingDays.push({month:currentMonth, day: newDay});
                    precedingDays.push(new Date(currentYear, currentMonth, newDay));
            }
        }
        else
            //precedingDays.push({month:currentMonth, day: newDay});
            precedingDays.push(new Date(currentYear, currentMonth, newDay));
    }
    //console.log(precedingDays);
    return precedingDays;
}

function getEndingOffset(month, currentYear)
{
    if(ThirtyOneDaysMonths.indexOf(month) !== -1)
        return 31;
    else if(ThirtyDaysMonths.indexOf(month) !== -1)
        return 30;
    else
    {
        var leapYear = isLeapYear(currentYear);
        if(leapYear)
            return 29;
        else
            return 28;
    }
}

function getPreviusDaysAligned(dayOfTheMonth, currentYear, currentMonth, prevDays = 3)
{
    /*
    console.log("***Recibiendo en getPreviusDaysAligned");
    console.log("dayOfTheMonth:"+dayOfTheMonth);
    console.log("currentMonth:"+currentMonth);
    console.log("currentYear: "+currentYear);
    console.log("prevDays: "+prevDays);*/
    //var leftItems = 3;
    var leftItems = prevDays;
    var previusDays = []
    for(var i = 0; i < leftItems; i++) //first we fill the array with undefined values
    {
        previusDays.push(undefined);
    }
    for(var i = 1; i <= leftItems; i++)
    {
        var newDay = dayOfTheMonth - i;
        if (newDay > 0)//this way we only fill previus days from the same month!
            ///previusDays[leftItems-i] = {month:currentMonth, day:newDay};
            previusDays[leftItems-i] = new Date(currentYear, currentMonth, newDay);
        else//we can break the loop here since all days will keep being < 0
            break;
    }
    //now we validate if there were undefined values (Meaning the month went out of days before filling all previus days required)
    if(previusDays.indexOf(undefined) !== -1)
    {
        var i, j;
        if(currentMonth === 0)//january's previuos month is December (11)
        {
            currentMonth = 12; //so it will get decreased in the line below, making it a 11 and thats what we want, (December = 11 in a 0 based index)
            currentYear = currentYear-1; //we have to decrease the year too
        }
        j = getEndingOffset(currentMonth -1, currentYear);//Because a month can be 31, 30,29 or 28 days long zzzzz
        for(i = leftItems-1; i >= 0; i--)
        {
            if(previusDays[i] === undefined)
            {
                //previusDays[i] = getDayFromMonthTail(currentMonth-1, currentYear,j);
                previusDays[i] = new Date(currentYear, currentMonth-1, j);
                j--;
            }
        }
    }
    return previusDays;
}


function getDayFromMonthTail(currentMonth, currentYear, tailIndex)
{
    if(ThirtyDaysMonths.indexOf(currentMonth) !== -1)//Its a 30days month
        //return {month:currentMonth, day:(30-tailIndex)};
        return new Date(currentYear, currentMonth, (30-tailIndex));
    else if(ThirtyOneDaysMonths.indexOf(currentMonth) !== -1)//Its a 31days month
        //return {month:currentMonth, day: (31-tailIndex)};
        return new Date(currentYear, currentMonth, (31-tailIndex));
    else//its a weirdMonth (its size depends of leap-year value)
    {
        if(isLeapYear(currentYear))//Confirmed leap-year
            //return {month:currentMonth, day: (29-tailIndex)};
            return new Date(currentYear, currentMonth, (29-tailIndex));
        else
            //return {month:currentMonth, day: (28-tailIndex)};
            return new Date(currentYear, currentMonth, (28-tailIndex));
    }   
}

export function getWeekDelimitersAsString()
{
    var currentDate = new Date();
    var dayIndex = currentDate.getDay();
    var prevDays = getPreviusDaysAligned(currentDate.getDate(), currentDate.getFullYear(), currentDate.getMonth(), (dayIndex-1)); //dayIndex peero como acá tomamos semana desde Lunes, por eso le restamos uno
    var precedingDays = getPrecedingDaysAligned(currentDate.getDate(), currentDate.getFullYear(), currentDate.getMonth(), (7-dayIndex));
    var retStr;
    if(prevDays.length === 0)//osea ya teniamos el primer dia
        retStr = currentDate.toLocaleDateString("es-ES",{month: 'long', day: 'numeric' });
    else
        retStr = prevDays[0].toLocaleDateString("es-ES",{month: 'long', day: 'numeric' });
    retStr = retStr + " a ";
    if(precedingDays.length === 0)//osea ya teniamos el ultimo dia
        retStr = retStr + currentDate.toLocaleDateString("es-ES",{month: 'long', day: 'numeric' });
    else
        retStr = retStr + precedingDays[precedingDays.length-1].toLocaleDateString("es-ES",{month: 'long', day: 'numeric' });
    return retStr;
}

export function beautifyDate(date)
{
    var retStr=date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
    return retStr;
}

///////////////////////////////ZZZZ Gotta remake it, atleast its easier than the previous one
export default function getCurrentWeek(date)
{
    //var isLeap = isLeapYear(date.getFullYear());
    //var dayIndex = date.getDay();
    var dayIndex = date.getDay();//because it getDay return 0 for first day
    var monthIndex = date.getMonth();
    var dayOfTheMonth = date.getDate();
    var year = date.getFullYear();
    if(dayIndex != 0)
    {
        var week = getPreviusDaysAligned(dayOfTheMonth, year, monthIndex, dayIndex == 0 ? 0 : Math.abs(1-dayIndex));
        //week.push({month:monthIndex, day: dayOfTheMonth});

        week.push(new Date(year, monthIndex, dayOfTheMonth));
        week = week.concat(getPrecedingDaysAligned(dayOfTheMonth, year, monthIndex, 7-dayIndex));
        //console.log(week);
        return {week:week, currentIndex: dayIndex-1};
    }
    else{
        var week = getPreviusDaysAligned(dayOfTheMonth, year, monthIndex, 6);
        week.push(new Date(year, monthIndex, dayOfTheMonth));
        return {week:week, currentIndex: 6};
    }
}

export function getNextWeek(date)
{
    var monthIndex = date.getMonth();
    var dayOfTheMonth = date.getDate();
    var year = date.getFullYear();
    var newWeek = getPrecedingDaysAligned(dayOfTheMonth, year, monthIndex, 7);//Seven because i want the whole new week to be rendered
    return {week: newWeek, currentIndex: 0};
}

export function getPreviousWeek(date)
{
    var monthIndex = date.getMonth();
    var dayOfTheMonth = date.getDate();
    var year = date.getFullYear();
    var newWeek = getPreviusDaysAligned(dayOfTheMonth, year, monthIndex, 7);//Seven because i want the whole new week to be rendered
    return {week: newWeek, currentIndex: 6};
}


export function getFormattedDate(date)
{
    var monthIndex = date.getMonth() + 1;//Because getMonth returns it zero based
    if(monthIndex < 10)
        monthIndex= ("0"+monthIndex);//Adding zero when months are < 10, as required by backend 
    var yearIndex = date.getFullYear();
    var day = date.getDate();
    if (day < 10)
        day=("0"+day);//Adding zero when days are < 10, as required by backend 
    return(yearIndex+"-"+monthIndex+"-"+day);

}

export function drupalDateFormatter(date, separator = "-")
{
    //drupal dates come like this YYYY-MM-DD
    var retString = "";
    var pieces = date.split(separator);
    retString+=pieces[2]+"/";
    retString+=pieces[1]+"/";
    retString+=pieces[0];
    //now its DD/MM/YYYY
    return retString;
}

