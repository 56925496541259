import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var PrayingHandsIcon = (props)=>{
    return(
        <SvgIcon {...props}>
            <svg
              aria-hidden="true"
              data-prefix="fab"
              data-icon="github-alt"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="m216.329 17.722c-12.189 0-22.68 9.076-24.447 21.128l-32.022 251.021c-.488 3.823-2.432 7.31-5.427 9.736l-31.619 25.601 55.48 56.361 55.972-55.972c4.366-4.367 6.771-10.172 6.771-16.347v-266.821c0-13.623-11.084-24.707-24.708-24.707z"/><path d="m175.336 421.223-96.549-98.081-72.96 56.258c-6.974 5.378-7.819 15.585-1.823 22.036l81.848 88.066c5.793 6.233 15.612 6.387 21.597.337z"/><path d="m389.186 325.208-31.619-25.601c-2.995-2.426-4.939-5.913-5.427-9.736l-32.023-251.022c-1.766-12.051-12.258-21.128-24.447-21.128-13.624 0-24.708 11.084-24.708 24.708v266.82c0 6.175 2.405 11.98 6.771 16.347l55.972 55.972z"/><path d="m433.213 323.142-96.549 98.081 67.888 68.616c5.985 6.049 15.804 5.896 21.597-.337l81.848-88.066c5.995-6.451 5.151-16.658-1.823-22.036z"/>
            </svg>{" "}
        </SvgIcon>
    )
}
export default PrayingHandsIcon;