import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';
const svgIcons = [
    {///Intencion del dia icon
        icon: (
            <SvgIcon>
                <svg
                aria-hidden="true"
                data-prefix="fab"
                data-icon="github-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                >
                <path d="m216.329 17.722c-12.189 0-22.68 9.076-24.447 21.128l-32.022 251.021c-.488 3.823-2.432 7.31-5.427 9.736l-31.619 25.601 55.48 56.361 55.972-55.972c4.366-4.367 6.771-10.172 6.771-16.347v-266.821c0-13.623-11.084-24.707-24.708-24.707z"/><path d="m175.336 421.223-96.549-98.081-72.96 56.258c-6.974 5.378-7.819 15.585-1.823 22.036l81.848 88.066c5.793 6.233 15.612 6.387 21.597.337z"/><path d="m389.186 325.208-31.619-25.601c-2.995-2.426-4.939-5.913-5.427-9.736l-32.023-251.022c-1.766-12.051-12.258-21.128-24.447-21.128-13.624 0-24.708 11.084-24.708 24.708v266.82c0 6.175 2.405 11.98 6.771 16.347l55.972 55.972z"/><path d="m433.213 323.142-96.549 98.081 67.888 68.616c5.985 6.049 15.804 5.896 21.597-.337l81.848-88.066c5.995-6.451 5.151-16.658-1.823-22.036z"/>
                </svg>{" "}
            </SvgIcon>      
        )
    },
    {//Llamados a la vida eterna icon
        icon:(
            <SvgIcon>
                <svg
                aria-hidden="true"
                data-prefix="fab"
                data-icon="github-alt"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                >
                <path d="m404.617 120.5h-73.367v-73.367c0-26.304-21.351-47.133-47.133-47.133h-56.233c-26.304 0-47.133 21.351-47.133 47.133v73.367h-73.367c-26.304 0-47.133 21.351-47.133 47.133v56.233c0 26.304 21.351 47.133 47.133 47.133h73.367v193.867c0 26.304 21.351 47.133 47.133 47.133h56.233c26.304 0 47.133-21.351 47.133-47.133v-193.866h73.367c26.304 0 47.133-21.351 47.133-47.133v-56.233c0-26.305-21.351-47.134-47.133-47.134zm17.133 103.367c0 9.337-7.548 17.133-17.133 17.133h-88.367c-8.284 0-15 6.716-15 15v208.867c0 9.337-7.548 17.133-17.133 17.133h-56.233c-9.337 0-17.133-7.548-17.133-17.133v-208.867c0-8.284-6.716-15-15-15h-88.367c-9.337 0-17.133-7.548-17.133-17.133v-56.233c0-9.337 7.548-17.133 17.133-17.133h88.367c8.284 0 15-6.716 15-15v-88.368c0-9.337 7.548-17.133 17.133-17.133h56.233c9.337 0 17.133 7.548 17.133 17.133v88.367c0 8.284 6.716 15 15 15h88.367c9.337 0 17.133 7.548 17.133 17.133z"/>
                </svg>{" "}
            </SvgIcon>    
        )
    },
    {///Directorio de hermanos icon
        icon:(
            <SvgIcon>
                <svg
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="github-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill={window.innerWidth > 959 ? "inherit" : "white"}
                    d="M496,336h-32v-80c0-8.832-7.168-16-16-16H272v-64h32c8.832,0,16-7.168,16-16V64c0-8.832-7.168-16-16-16h-96
                    c-8.832,0-16,7.168-16,16v96c0,8.832,7.168,16,16,16h32v64H64c-8.832,0-16,7.168-16,16v80H16c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16H80v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16h-32v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96C512,343.168,504.832,336,496,336z"
                  />
                </svg>{" "}
            </SvgIcon>
        )
    },
    {///Directorio de hermanos icon BLACK zzz cant set fill attribute dinamically zzzz
        icon:(
            <SvgIcon>
                <svg
                  aria-hidden="true"
                  data-prefix="fab"
                  data-icon="github-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill={"black"}
                    d="M496,336h-32v-80c0-8.832-7.168-16-16-16H272v-64h32c8.832,0,16-7.168,16-16V64c0-8.832-7.168-16-16-16h-96
                    c-8.832,0-16,7.168-16,16v96c0,8.832,7.168,16,16,16h32v64H64c-8.832,0-16,7.168-16,16v80H16c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16H80v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96c0-8.832-7.168-16-16-16h-32v-64h160v64h-32c-8.832,0-16,7.168-16,16v96
                    c0,8.832,7.168,16,16,16h96c8.832,0,16-7.168,16-16v-96C512,343.168,504.832,336,496,336z"
                  />
                </svg>{" "}
            </SvgIcon>
        )
    }

]
export default svgIcons;