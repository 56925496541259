import React from "react";
import Icon from '@material-ui/core/Icon';
import { makeStyles} from '@material-ui/core/styles';

///core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Box from '@material-ui/core/Box'
import { TextField } from "@material-ui/core";
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
const useStyles = makeStyles((theme) => ({
    number: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0
        }
      },
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0
        }
      },
      Lborder:{
          cursor:"pointer",
          paddingTop:"5px",
          paddingBottom:"5px",
          background:"white",
          borderTopLeftRadius:"6px",
          borderBottomLeftRadius:"6px",
          color:"black"
      },
      innerElement:{
        cursor:"pointer",
        paddingTop:"5px",
        paddingBottom:"5px",
        background:"white",
        color:"black"
      },
      Rborder:{
        cursor:"pointer",
        paddingTop:"5px",
        paddingBottom:"5px",
        background:"white",
        borderTopRightRadius:"6px",
        borderBottomRightRadius:"6px", 
        color:"black"
    }
  }));


export default function PageNavigator(props)
{
    const {setPage, rootIndex, maxValue} = props;
    //const [index, setLocalIndex] = React.useState((rootIndex !== undefined ? rootIndex : 1))
    
    const handlePageChange = (newIndex) =>{
        //console.log(newIndex)
        if(Number.isInteger(newIndex) === false)
            return;
        if(newIndex < 0 || newIndex > +(maxValue))// it was < 1 but it was not letting the user go from 11 to 3, because that would require the input to take an empty (0) value for a sec
            return;
        setPage(newIndex);
    }
    const classes = useStyles();
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{display:"flex"}}>
                    <div style={{backgroundColor:'red', display:'flex', flex:'100%', boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)", borderRadius:'6px'}}>
                    <Box flex="20%" className={classes.Lborder} textAlign="center" onClick={()=>{handlePageChange(1)}}>
                        <Icon fontSize="large" className="fa fa-angle-double-left"/>
                    </Box>
                    <Box flex="20%" className={classes.innerElement} textAlign="center" onClick={()=>{handlePageChange(rootIndex-1)}}>
                        <Icon fontSize="large" className="fa fa-angle-left"/>
                    </Box>
                    <Box flex="20%" className={classes.innerElement} textAlign="center">
                        <TextField type="tel"  value={rootIndex} onChange={(event)=>{handlePageChange(+event.target.value);}} inputProps={{style:{textAlign:"center", fontFamily:"IndivisaTextSansRegular"}, className:classes.input}}/>
                    </Box>
                    <Box flex="20%" className={classes.innerElement} textAlign="center" onClick={()=>{handlePageChange(rootIndex+1)}}>
                        <Icon fontSize="large" className="fa fa-angle-right"/>
                    </Box>
                    <Box flex="20%" className={classes.Rborder} textAlign="center" onClick={()=>{handlePageChange(11)}}>
                        <Icon fontSize="large" className="fa fa-angle-double-right"/>
                    </Box>
                    </div>
                </GridItem>
            </GridContainer>
        </div>

    )

}