/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import copyRightSVG from "assets/img/copyRight.svg";

const useStyles = makeStyles(styles);
export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer} style={{display:'flex', justifyContent:'center'}}>
      
        <img style={{width:'360px', height:'155px'}} src={copyRightSVG} alt="CopyRight"/>
        
    </footer>
  );
}
