import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
///react-router
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Poppers from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { yellowColor } from "assets/jss/material-dashboard-react.js";
import { authenticatedNavbarElements } from "routes.js";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// core components
import goBackSvg from "assets/img/Icons/diagram.svg";
import MenuIcon from "icons/MenuIcon";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import styles2 from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { doCheckForAuthResult, logout } from "services/UserService";
import { isInMainPage } from "routes";

import { apiHome, logoutRoute } from "variables/restApi.js";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import AuthenticatedNavbar from "components/AuthenticatedNavbar/AuthenticatedNavbar";
import SvgIcon from "@material-ui/icons/Menu";
import GoBackIcon from "icons/GoBackIcon";
import AccountCircleIcon from "icons/AccountCircleIcon";
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);
export default function Header(props) {
	const { color, logoMobile, history } = props;
	const [loggedState, setLoggedState] = React.useState(false);
	const [openUserMenu, setOpenUserMenu] = React.useState(null);
	const classes = useStyles();
	var isInMain = isInMainPage(props.location.pathname)

	const handleClickUserMenu = event => {
		if (openUserMenu && openUserMenu.contains(event.target)) {
			setOpenUserMenu(null);
		} else {
			setOpenUserMenu(event.currentTarget);
		}
	};

	const handleCloseUserMenu = () => {
		setOpenUserMenu(null);
	};
	const appBarClasses = classNames({
		[" " + classes[color]]: color
	});


	async function authCheck() {
		var retVal = await doCheckForAuthResult();
		setLoggedState(retVal);
	}

	React.useEffect(() => {
		authCheck();
	}, []);
	var userRep = JSON.parse(sessionStorage.getItem('usr'));
	return (
		<div>
			<Hidden mdUp>

				<AppBar style={{ background: "#004B92" }}>
					{isInMain && (
						<Toolbar display="flex" >
							<Box flex="10%">
								<IconButton

									color="inherit"
									aria-label="open drawer"
									onClick={props.handleDrawerToggle}
								>
									<MenuIcon />
								</IconButton>
							</Box>
							<Box flex="80%" textAlign="center">
								<img src={logoMobile} alt="logo" className={classes.img} style={{ width: "190px !important", background: "transparent !important" }} />
								
							</Box>
							{/* SEARCH Feature not ready yet, NOTE this box and the one below must use 10%. Because of this feaute not being ready
            the flex of the box below will be 10%. But it will be shared equally (5% each) when search lauching search feature
            <Box flex="5%" >
            <IconButton      
                color="inherit"
                aria-label="open drawer"
                onClick={() =>console.log("inner icon")}
              >
                <SearchIcon />
              </IconButton>
            </Box>
            */}
							{/*<Box flex="5%"  marginLeft="-15px"> Read aboves note, the marginLeft property must remain once search feaute is done*/}

							<Box flex="10%" >
								{!loggedState ? (
									<Link to={{ pathname: "/login" }} style={{ color: "inherit" }}>
										<div style={{ fontWeight: "bold", cursor: "pointer" }}>
											Entrar
										</div>
									</Link>
								) :
									(
										<IconButton
											color="inherit"
											aria-label="open drawer"
											onClick={() => console.log("outter icon")}
										>
											<UserInfoModal
												userRep={userRep}
											/>
										</IconButton>
									)
								}

							</Box>
						</Toolbar>)}
					
					{isInMain === false && (
						<Toolbar display="flex" >
							<Box flex="10%" textAlign="left" >
								<IconButton

									color="inherit"
									aria-label="open drawer"
									onClick={() => { history.goBack() }}
								>
									<ArrowBack />
								</IconButton>
							</Box>
							<Box flex="90%" textAlign='right'  style={{ marginRight:'-10px'}} > {/*PaddingRight to make it match with leftPadding in goBack Icon Button */}
								<img src={logoMobile} alt="logo" className={classes.img} style={{ width: "190px !important", background: "transparent !important" }} />
							</Box>

						</Toolbar>)}
				</AppBar>
				{/*
          <AuthenticatedNavbar
            options={authenticatedNavbarElements}
          />*/}
				{/*
          <div style={{display:"flex", marginTop:"56px", marginBottom:"-56px"}}>
          <div   style={{flex:"20%", textAlign:"center"  }}>
            <Icon>exit_to_app</Icon>
          </div>
          <div style={{flex:"20%", textAlign:"center"}}>
          <Icon>exit_to_app</Icon>
          </div>
          <div style={{flex:"20%", textAlign:"center"}}>
          <Icon>exit_to_app</Icon>
          </div>
          <div style={{flex:"20%", textAlign:"center"}}>
          <Icon>exit_to_app</Icon>
          </div>
          <div style={{flex:"20%", textAlign:"center"}}>
          <Icon>exit_to_app</Icon>
          </div>
          </div>*/}
			</Hidden>
			<Hidden smDown implementation="css">
				<AppBar style={{ paddingLeft: "15px", paddingRight: "15px" }} className={classes.appBar + appBarClasses}> {/* the padding is to make it match with all the cards (GridItems) in the page's body */}
					<Toolbar className={classes.container}>
						{/*
          <Hidden mdUp implementation="css">
          
            
          </Hidden> */}
						{/* This whole div is the one rendering the current page name */}
						{/*
          <div className={classes.flex}> 
            <Button color="transparent" href="#" className={classes.title}>
              {makeBrand()}
            </Button>
          </div>*/}

						{/*
        <div className={classes2.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes2.margin + " " + classes2.search
            }}
            inputProps={{
              placeholder: "Buscar",
              inputProps: {
                "aria-label": "Buscar"
              }
            }}
          />
          <Button color="white" aria-label="edit" justIcon round>
            <SearchIcon />
          </Button>
        </div>
           Instead of search icon, we are going to display goBack icon there*/ }
						<Button color="white" aria-label="GoBack" justIcon round
							onClick={() => { history.goBack() }}

						>
							<ArrowBack style={{ color: "black" }} />
						</Button>



						<div className={classes.flex}>
							{""}
						</div>
						{/*
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks 
              sessionState = {sessionState}
              sessionCtrl = {sessionCtrl}
              renderExtraNavBar = {isInMain}
            />}
          */}
						<div className={classes.manager}>
							{loggedState ? (
								<Button onClick={handleClickUserMenu} color="white" aria-label="Cuenta" justIcon round >
									{/*<Icon style={{color:"black"}}>account_circle</Icon>*/}
									<AccountCircleIcon style={{ color: "black" }} />
								</Button>
							) :
								(
									<Link to={{ pathname: "/login" }} style={{ color: "white !important" }}>

										<Button  aria-label="Cuenta" title="Login" style={{backgroundColor:'#004B92', color:'white', fontWeight: "bold", cursor: "pointer" }}>
											Iniciar sesión
										</Button>
									</Link>
								)}

							{/*<Link to={{pathname: "/login"}} style={{color:"inherit"}}>*/}

							{loggedState ?
								(
									<Poppers
										open={Boolean(openUserMenu)}
										anchorEl={openUserMenu}
										transition
										disablePortal
										className={
											classNames({ [classes.popperClose]: !openUserMenu }) +
											" " +
											classes.popperNav
										}
									>
										{({ TransitionProps, placement }) => (
											<Grow
												{...TransitionProps}
												id="notification-menu-list-grow"
												style={{
													transformOrigin:
														placement === "bottom" ? "center top" : "center bottom"
												}}
											>
												<Paper>
													<ClickAwayListener onClickAway={handleCloseUserMenu}>
														<div style={{ borderRadius: "6px", color: "white", background: "#004B92", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "15px", paddingRight: "15px" }}>
															<div style={{ display: "flex", justifyContent: "center" }}>
																{userRep.user_picture_2 != "" ? (<img style={{ width: "50px", height: "50px", borderRadius: "30px" }} src={(apiHome + (userRep.user_picture_2))}></img>) :
																	/*<Icon  fontSize="large" style={{color:"black"}}>account_circle</Icon>*/
																	<AccountCircleIcon fontSize="large" style={{ color: "black" }} />
																}
															</div>
															<div style={{ textAlign: "center", fontSize: "16px" }}>
																{userRep.name}
																<br />
																{userRep.mail}
																<br />
																<a href={logoutRoute} onClick={() => logout()} style={{ color: 'inherit' }}>
																	<div style={{ fontFamily: "IndivisaTextSansBold", fontSize: "18px", borderRadius: "6px", background: yellowColor[0], width: "fitContent", marginTop: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
																		Salir
																	</div>
																</a>
															</div>

														</div>
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Poppers>
								) : null
							}

							{/*</Link>]*/}
						</div>
					</Toolbar>
				</AppBar>
			</Hidden>
		</div>

	);
}

Header.propTypes = {
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	logo: PropTypes.string,
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	routes: PropTypes.arrayOf(PropTypes.object)
};
