import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
var HierarchyIcon = (props)=>{
    return(
      <SvgIcon {...props}>
      <svg
      aria-hidden="true"
      data-prefix="fab"
      
      fill="white"
      
      xmlns="http://www.w3.org/2000/svg"
      
      id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.4 144.3"
      >                                    
      <polygon class="cls-1" points="118.32 26.75 83.2 42.36 48.08 26.75 20.51 116.49 68.24 127.55 83.2 61.22 98.16 127.55 145.89 116.49 118.32 26.75"/>
      </svg>{" "}
    </SvgIcon>
    )
}
export default HierarchyIcon;