/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';
///Recordatorios Submenu related
import Poppers from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import PrintModal from "components/PrintModal/PrintModal";
import isLoggedIn, { doCheckForAuthResult } from "services/UserService";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import CakeIcon from "icons/CakeIcon";
import QueryBuilderIcon from "icons/QueryBuilderIcon";
import MenuIcon from "icons/MenuIcon";

const useStyles = makeStyles(styles);

function Sidebar(props) {
	const [openNotification, setOpenNotification] = React.useState(null);
	const [openAniversario, setOpenAniversarios] = React.useState(null);
	const [childRoute = "#", setChildRoute] = React.useState();
	const [loggedState, setLoggedState] = React.useState(false);
	const [localOpenState, setLocalOpenState] = React.useState(true);
	const loggedIn = props.sessionCtrl;
	const setLoggedIn = props.sessionState;
	const classes = useStyles();
	const theme = useTheme();
	const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

	async function authCheck() {
		var retVal = await doCheckForAuthResult();
		setLoggedState(retVal);
	}

	React.useEffect(() => {

		authCheck();
	}, []);

	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return window.location.href.indexOf(routeName) > -1 ? true : false;
	}
	const { color, logo, image, logoText, routes, authenticatedRoutes } = props;
	const handleClickNotification = event => {
		//console.log("efe")
		if (openNotification && openNotification.contains(event.target)) {
			setOpenNotification(null);
		} else {
			setOpenNotification(event.currentTarget);
		}
	};

	const handleSideBarOpen = () => {
		if (isMobileDevice)
			props.handleDrawerToggle();
	}

	const handleClickAniversarios = event => {
		//console.log("efe")
		if (openAniversario && openAniversario.contains(event.target)) {
			setOpenAniversarios(null);
		} else {
			setOpenAniversarios(event.currentTarget);
		}
	}
	const handleClickAway = (menu) => {
		//console.log("HandleClickAway")
		if (menu === 1)
			setOpenNotification(null);
		else
			setOpenAniversarios(null);
	}
	const handleCloseNotification = (childOption) => {
		//console.log("Clicking popper")
		var headerRep = {
			cardHeaderColor: "danger",
			cardIconColor: "warning",
			icon: "cake",
			typeId: 9,
			title: "Cumpleaños del "
		};
		if (childOption === 1)
			props.history.push({ pathname: "/element", state: { type: headerRep.typeId, title: headerRep.title, icon: headerRep.icon, iconColor: headerRep.cardIconColor, extraFetch: true } });
		else if (childOption === 2) {
			headerRep = {
				cardHeaderColor: "danger",
				cardIconColor: "success",
				icon: "query_builder",
				typeId: 2,
				title: "Efemérides del "
			};
			props.history.push({ pathname: "/element", state: { type: headerRep.typeId, title: headerRep.title, icon: headerRep.icon, iconColor: headerRep.cardIconColor, extraFetch: true } });

		}
	};

	const handleCloseAniversarios = (childOption) => {
		//console.log("Clicking popper")
		var headerRep = {
			viewType: childOption
		};
		props.history.push({ pathname: "/aniversarios", state: headerRep });

	};

	var renderPoppers = (prop, key) => {
		var activePro = " ";
		var listItemClasses;
		listItemClasses = classNames({
			[" " + classes[color]]: activeRoute(prop.path)
		});

		const whiteFontClasses = classNames({
			[" " + classes.whiteFont]: activeRoute(prop.path)
		});
		return (
			<div
				className={activePro + classes.item}
				key={key}
				onClick={handleClickNotification}
			//onClick={()=>{console.log("Clicking upper")}}
			>
				<ListItem button className={classes.itemLink + listItemClasses}>
					{typeof prop.icon === "string" ? (
						<Icon
							className={classNames(classes.itemIcon, whiteFontClasses, {
								[classes.itemIconRTL]: props.rtlActive
							})}
						>
							{prop.icon}
						</Icon>
					) : (
						<prop.icon
							className={classNames(classes.itemIcon, whiteFontClasses, {
								[classes.itemIconRTL]: props.rtlActive
							})}
						/>
					)}
					<ListItemText
						primary={props.rtlActive ? prop.rtlName : prop.name}
						className={classNames(classes.itemText, whiteFontClasses, {
							[classes.itemTextRTL]: props.rtlActive
						})}
						disableTypography={true}
					/>
				</ListItem>
				<Poppers
					open={Boolean(openNotification)}
					anchorEl={openNotification}
					transition
					disablePortal
					className={
						classNames({ [classes.popperClose]: !openNotification }) +
						" " +
						classes.popperNav
					}
					style={{ zIndex: "3" }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							id="notification-menu-list-grow"
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom"
							}}
						>
							<Paper >
								<ClickAwayListener onClickAway={() => { handleClickAway(1) }}>
									<MenuList role="menu" >

										<MenuItem
											onClick={() => { handleCloseNotification(1);  /*setChildRoute("/cumpleaños"); handleCloseNotification(1);*/ }}
											onTouchEnd={() => { handleCloseNotification(1); }}
											className={classes.dropdownItem}
										>



											<ListItemIcon >
												{/* <Icon fontSize="small">cake</Icon> */}
												<CakeIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="inherit">Cumpleaños</Typography>

										</MenuItem>
										<MenuItem
											onClick={() => { handleCloseNotification(2); /*setChildRoute("/efemerides"); handleCloseNotification(1);*/ }}
											onTouchEnd={() => { handleCloseNotification(2); }}
											className={classes.dropdownItem}
										>
											<ListItemIcon >
												{/*<Icon fontSize="small">query_builder</Icon>*/}
												<QueryBuilderIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="inherit">Efemérides</Typography>
										</MenuItem>

									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Poppers>
			</div>
		);
	};

	var renderPoppersAniversarios = (prop, key) => {
		var activePro = " ";
		var listItemClasses;
		listItemClasses = classNames({
			[" " + classes[color]]: activeRoute(prop.path)
		});

		const whiteFontClasses = classNames({
			[" " + classes.whiteFont]: activeRoute(prop.path)
		});
		return (
			<div
				className={activePro + classes.item}
				key={key}
				onClick={handleClickAniversarios}
			//onClick={()=>{console.log("Clicking upper")}}
			>
				<ListItem button className={classes.itemLink + listItemClasses}>
					{typeof prop.icon === "string" ? (
						<Icon
							className={classNames(classes.itemIcon, whiteFontClasses, {
								[classes.itemIconRTL]: props.rtlActive
							})}
						>
							{prop.icon}
						</Icon>
					) : (
						<prop.icon
							className={classNames(classes.itemIcon, whiteFontClasses, {
								[classes.itemIconRTL]: props.rtlActive
							})}
						/>
					)}
					<ListItemText
						primary={props.rtlActive ? prop.rtlName : prop.name}
						className={classNames(classes.itemText, whiteFontClasses, {
							[classes.itemTextRTL]: props.rtlActive
						})}
						disableTypography={true}
					/>
				</ListItem>
				<Poppers
					open={Boolean(openAniversario)}
					anchorEl={openAniversario}
					transition
					disablePortal
					className={
						classNames({ [classes.popperClose]: !openAniversario }) +
						" " +
						classes.popperNav
					}
					style={{ zIndex: "3" }}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							id="notification-menu-list-grow"
							style={{
								transformOrigin:
									placement === "bottom" ? "center top" : "center bottom"
							}}
						>
							<Paper >
								<ClickAwayListener onClickAway={() => { handleClickAway(2) }}>
									<MenuList role="menu" >

										<MenuItem
											onClick={() => { handleCloseAniversarios(1);  /*setChildRoute("/cumpleaños"); handleCloseNotification(1);*/ }}
											onTouchEnd={() => { handleCloseAniversarios(1); }}
											className={classes.dropdownItem}
										>



											<ListItemIcon >
												{/*<Icon fontSize="small">menu</Icon>*/}
												<MenuIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="inherit">Votos temporales</Typography>

										</MenuItem>
										<MenuItem
											onClick={() => { handleCloseAniversarios(2); /*setChildRoute("/efemerides"); handleCloseNotification(1);*/ }}
											onTouchEnd={() => { handleCloseAniversarios(2); }}
											className={classes.dropdownItem}
										>
											<ListItemIcon >
												<MenuIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="inherit">Votos perpétuos</Typography>
										</MenuItem>
										<MenuItem
											onClick={() => { handleCloseAniversarios(3); /*setChildRoute("/efemerides"); handleCloseNotification(1);*/ }}
											onTouchEnd={() => { handleCloseAniversarios(3); }}
											className={classes.dropdownItem}
										>
											<ListItemIcon >
												<MenuIcon fontSize="small" />
											</ListItemIcon>
											<Typography variant="inherit">Toma de hábito</Typography>
										</MenuItem>

									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Poppers>
			</div>
		);
	}


	var links = (
		<List className={classes.list}>
			{routes.map((prop, key) => {
				var activePro = " ";
				var listItemClasses;
				//console.log("prop.path es -->"+prop.path);
				if (prop.hiddenElement == true)
					return null;
        /*if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else */ {
					listItemClasses = classNames({
						[" " + classes[color]]: activeRoute(prop.path)
					});
				}
				const whiteFontClasses = classNames({
					[" " + classes.whiteFont]: activeRoute(prop.path)
				});

				if (prop.path === "/recordatorios")
					return renderPoppers(prop, key);
				return (
					<NavLink
						to={prop.path}
						className={activePro + classes.item}
						activeClassName="active"
						key={key}
						onClick={handleSideBarOpen}
					>
						<ListItem button className={classes.itemLink + listItemClasses} >
							<div>
								{typeof prop.icon === "string" ? (
									<Icon
										className={classNames(classes.itemIcon, whiteFontClasses, {
											[classes.itemIconRTL]: props.rtlActive
										})}
									>
										{prop.icon}
									</Icon>
								) : (
									<prop.icon
										className={classNames(classes.itemIcon, whiteFontClasses, {
											[classes.itemIconRTL]: props.rtlActive
										})}
									/>
								)}
							</div>
							<div>
								<ListItemText
									primary={props.rtlActive ? prop.rtlName : prop.name}
									className={classNames(classes.itemText, whiteFontClasses, {
										[classes.itemTextRTL]: props.rtlActive
									})}
									disableTypography={true}
								/>
							</div>
						</ListItem>
					</NavLink>
				);
			})}

		</List>
	);

	var authenticatedLinks = (
		<List className={classes.list}>
			{
				authenticatedRoutes.map((prop, key) => {
					var activePro = " ";
					var listItemClasses;
					//console.log("prop.path es -->"+prop.path);
					if (prop.hiddenElement === true)
						return null;
        /*if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else */ {
						listItemClasses = classNames({
							[" " + classes[color]]: activeRoute(prop.path)
						});
					}
					const whiteFontClasses = classNames({
						[" " + classes.whiteFont]: activeRoute(prop.path)
					});

					if (prop.path === "/aniversariosP")
						return renderPoppersAniversarios(prop, key);
					if (prop.path === "calendarModal" && prop.requiresRender === false)
						return (
							<div key={key} className={activePro + classes.item}>
								<ListItem button className={classes.itemLink + listItemClasses}>
									<PrintModal />

								</ListItem>
							</div>
						)
					return (
						<NavLink
							to={prop.path}
							className={activePro + classes.item}
							activeClassName="active"
							key={key}
							onClick={handleSideBarOpen}
						>
							<ListItem button className={classes.itemLink + listItemClasses}>
								<div>
									{typeof prop.icon === "string" ? (
										<Icon
											className={classNames(classes.itemIcon, whiteFontClasses, {
												[classes.itemIconRTL]: props.rtlActive
											})}
										>
											{prop.icon}
										</Icon>
									) : (
										<prop.icon
											className={classNames(classes.itemIcon, whiteFontClasses, {
												[classes.itemIconRTL]: props.rtlActive
											})}
										/>
									)}
								</div>
								<div style={{ name: "test" }}>
									<ListItemText
										primary={props.rtlActive ? prop.rtlName : prop.name}
										className={classNames(classes.itemText, whiteFontClasses, {
											[classes.itemTextRTL]: props.rtlActive
										})}
										disableTypography={true}
									/>
								</div>
							</ListItem>
						</NavLink>
					);
				})
			}

		</List>
	)

	function renderAuthenticatedLinks() {

		if (loggedState === false)
			return null;
		else {
			return (
				<div>
					<div className={classes.hrContainer}>
						<div className={classes.hrBorder} />
						<span style={{ paddingLeft: "10px", paddingRight: "10px" }} className={classes.hrContent}>Hermano</span>
						{/*<span style={{paddingLeft:"5px", paddingRight:"10px"}} className={classes.hrContent}>Lasallista</span>}*/}
						<div className={classes.hrBorder} />
					</div>
					{authenticatedLinks}
				</div>)

		}
	}

	var brand = (

		<div style={{ display: "flex", justifyContent: "center", position: "relative", zIndex: "4" }}>
			<div className={classes.logo}>
				<a
					style={{
						display: "block"
					}}
					href="/"
					onClick={(event) => {
						//event.preventDefault();
					}}
					className={classNames(classes.logoLink, {
						[classes.logoLinkRTL]: props.rtlActive
					})}

				>
					<div className={classes.logoImage} style=
						{{
							width: isMobileDevice ? "170px" : "100%",
							height: isMobileDevice ? "250px" : undefined,
							margin: "0 auto",
							maxHeight: isMobileDevice ? "70px" : undefined,
						}}>
						<img src={logo} alt="logo" className={classes.img} style={{
							width: isMobileDevice ? "100%" : "80%"
						}} />
					</div>
				</a>
			</div>
			{isMobileDevice ? (
				<div style={{ width: "100%", display: "flex", justifyContent: "flex-end", paddingTop: "15px", paddingRight: "20px", position: "absolute", zIndex: "5" }} onClick={handleSideBarOpen}>
					<div style={{ cursor: "pointer", color: "white", fontSize: "43px", fontWeight: "bold" }}>
						&times;
					</div>
				</div>) : null
			}
		</div>

	);

	return (
		<div>

			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={"top"}
					open={props.open && localOpenState}
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: true
						})
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}

					<div className={classes.sidebarWrapper}>
						<div className="container-nav">
							{links}

							{renderAuthenticatedLinks()}
						</div>

					</div>
					<div
						className={classes.background}
						style={{ background: "#004B92" }}
					/>
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					anchor={props.rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
						{renderAuthenticatedLinks()}
					</div>
					{image !== undefined ? (
						<div
							className={classes.background}
							style={{ backgroundImage: "url(" + image + ")" }}
						/>
					) : (
						<div
							className={classes.background}
							style={{ background: "#004B92" }}
						/>
					)}
				</Drawer>
			</Hidden>
		</div>
	);
}

Sidebar.propTypes = {
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red", "yellow"]),
	logo: PropTypes.string,
	image: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool
};
export default withRouter(Sidebar);