/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import EmojiObjectsIcon from 'icons/EmojiObjectsIcon';
import CalendarTodayIcon from 'icons/CalendarTodayIcon';
import LocalLibraryIcon from 'icons/LocalLibraryIcon';
import LocationCityIcon from 'icons/LocationCityIcon';
import CakeIcon from 'icons/CakeIcon';
import FaceIcon from 'icons/FaceIcon';
import LandscapeIcon from 'icons/LandscapeIcon';
import HouseIcon from 'icons/HouseIcon';
import QueryBuilderIcon from 'icons/QueryBuilderIcon';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import CaledarioPage from "views/Calendario/Calendario.js";
import GenericViewPage from "views/Calendario/ChildViews/GenericView.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import NotificationsPage from "views/Notifications/Notifications.js";
import ComunidadesPage from "views/Comunidades/Comunidades.js";
// core components/views for RTL layout
import Meditaciones from "views/Meditaciones/Meditaciones";
import DirectorioHermanos from "views/DirectorioHermanos/DirectorioHermanos";
import Aniversarios from 'views/Aniversarios/Aniversarios';
import {loginRoute} from 'variables/restApi.js';
import HierarchyIcon from "icons/HierarchyIcon";
const dashboardRoutes = [

  {
    path:"/login",
    hiddenElement:true,
    externalLink:loginRoute,
    layout: "/admin"
  },  
  {
    path: "/calendario",
    name: "Calendario",
    icon: CalendarTodayIcon,
    component: CaledarioPage,
    layout: "/admin"
  },
  {
    path: "/element",
    name: "GenericView",
    icon: CalendarTodayIcon,
    component: GenericViewPage,
    layout: "/admin",
    hiddenElement: true
  },
  {
    path: "/lasallecanta",
    name: "La Salle Canta",
    rtlName: "لوحة القيادة",
    //icon: Dashboard,
    icon: LibraryMusicIcon,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/novena",
    name: "Novena vocacional",
    rtlName: "ملف تعريفي للمستخدم",
    icon: LocalLibraryIcon,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/recordatorios",
    name: "Recordatorios",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: CaledarioPage,
    layout: "/admin",
    hiddenElement:true,
    poppers: [{text:"Cumpleaños", icon: CakeIcon}, {text:"Efemérides", icon: QueryBuilderIcon},{text:"Santidades", icon: FaceIcon }]
  },
  {
    path: "/santidades",
    name: "Santidad Lasaliana",
    rtlName: "طباعة",
    icon: FaceIcon,
    component: Typography,
    layout: "/admin",
    hiddenElement: true,
  },
  {
    path: "/pensamientos", ///Gotta hide it, until its completly done. Hidden for now
    name: "Pensamientos lasallistas",
    rtlName: "الرموز",
    icon: EmojiObjectsIcon,
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/meditaciones",
    name: "Meditaciones",
    rtlName: "خرائط",
    icon: LandscapeIcon,
    component: Meditaciones,
    layout: "/admin"
  },
  {
    path: "/obras",
    name: "Directorio de obras",
    rtlName: "إخطارات",
    icon: LocationCityIcon,
    component: NotificationsPage,
    layout: "/admin"
  },
  {
    path: "/comunidades",
    name: "Directorio de comunidades",
    rtlName: "إخطارات",
    icon: HouseIcon,
    component: ComunidadesPage,
    layout: "/admin"
  },
  
  
];


export const authenticatedRoutes = [

  
  {
    path: "/hermanos",
    name: "Hermanos",
    icon: HierarchyIcon,
    component: DirectorioHermanos,
    layout: "/admin"
  },
  {
    path: "/aniversariosP",
    name: "Aniversarios",
    icon: CakeIcon,
    component: Aniversarios,
    layout: "/admin",
    requiresRoute: false,
    hiddenElement: true
  },
  {
    path: "/aniversarios",
    name: "Aniversarios",
    icon: CakeIcon,
    component: Aniversarios,
    layout: "/admin",
    hiddenElement:true
  },
  {
    path: "calendarModal",
    requiresRender: false,
    name: "Imprime tu calendario",
    icon: CalendarTodayIcon,
    component: GenericViewPage,
    layout: "/admin"
  },
  {
    path: "",
    name: "Calendario",
    icon: CalendarTodayIcon,
    component: CaledarioPage,
    layout: "/admin",
    hiddenElement:true
  },
];

export const authenticatedNavbarElements = [
  {
    icon:"calendar_today"
  },
  {
    icon:"cake"
  },
  {
    icon:"exit_to_app"
  },
  {
    icon:"house"
  },
  {
    icon:"emoji_objects"
  }
];

export function isInMainPage(current){

  for(var i = 0; i < dashboardRoutes.length; i++)
  {
    if(current== "/" || current.indexOf("calendario") !== -1)
      return true;
    else
    {
      if(dashboardRoutes[i].path.indexOf(current) !== -1)
        return false;
    }
  }
  return true;  
}

export default dashboardRoutes;
