import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
///core components

const styles = {
    container: {
        background:"white",
        display:"flex",
        marginTop:"70px",
        marginLeft:"15px",
        marginRight:"15px",
        marginBottom:"-60px", //un tanto hechizo esto, pero toca :c
        borderRadius:"6px",
        color:"black"
    }
}

const useStyles = makeStyles(styles);
export default function AuthenticatedNavbar(props)
{
    const { options, color } = props;
    const classes = useStyles();
    console.log("props");
    console.log(props)
    
    return (
        <div className={classes.container}>
            {
                
                options.map((element, key)=>{
                    var individualPercent = (Math.round(100/options.length)+"%");
                    console.log("individualPercent es ->"+individualPercent)
                    return (
                        <div key={key} style={{flex:individualPercent, padding:"10px 0px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Icon>{element.icon}</Icon>
                        </div>
                    )
                })
            }
        </div>
    )
}